import { createSlice } from '@reduxjs/toolkit'

import { LANGUAGES } from 'constants/index'
import { getLocalLanguage, setLocalLanguage } from 'helpers/localstorage.helpers'
import {
	fetchStreamingsListThunk,
	fetchStreamingsCasinoGameListThunk
} from 'redux-thunk/thunk/streaming/streaming.thunk';

const defaultStreamingState = {
	language: getLocalLanguage() || LANGUAGES[0].languageCode,
	streamingsList: {
		streamings: [],
		total: 0
	},
	streamingsCasinoGameList: {
		streamings: [],
		total: 0
	},
}

const streamingSlice = createSlice({
	name: 'streamingSlice',
	initialState: defaultStreamingState,
	reducers: {
		handleLanguageChange: (state, action) => {
			setLocalLanguage(action.payload)
			return {
				...state,
				language: action.payload
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchStreamingsListThunk.fulfilled, (state, action) => {
				return {
					...state,
					streamingsList: action.payload
				}
			})
			.addCase(fetchStreamingsCasinoGameListThunk.fulfilled, (state, action) => {
				return {
					...state,
					streamingsCasinoGameList: action.payload
				}
			})
	}
})

export const { handleLanguageChange } = streamingSlice.actions

export default streamingSlice.reducer
