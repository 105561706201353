import { createSlice } from '@reduxjs/toolkit'
import { fetchBetHistoryDetailsThunk, fetchBonusClaimThunk, fetchBonusDetailsThunk, fetchSportsTransactionReportThunk, fetchTransactionReportsDetailsThunk } from 'redux-thunk/thunk/transaction/transaction.thunk'

const initialState = {
  transactionReportsDetailsData: [],
  bonusReportsDetailsData: [],
  betHistoryDetailsData: [],
  sportsTransactionReportData: [],
  transactionLoader: false,
  bonusClaimLoader:false
}

const transactionSlice = createSlice({
  name: 'transactionSlice',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionReportsDetailsThunk.fulfilled, (state, action) => {
        return {
          ...state,
          transactionReportsDetailsData: action?.payload?.transactions,
          transactionLoader: false
        }
      })
      .addCase(fetchTransactionReportsDetailsThunk.pending, (state, action) => {
        return {
          ...state,
          transactionLoader: true
        }
      })
      .addCase(fetchTransactionReportsDetailsThunk.rejected, (state, action) => {
        return {
          ...state,
          transactionReportsDetailsData: action.payload,
          transactionLoader: false
        }
      })
      .addCase(fetchBetHistoryDetailsThunk.fulfilled, (state, action) => {
        return {
          ...state,
          betHistoryDetailsData: action?.payload?.casinoTransaction,
          transactionLoader: false
        }
      })
      .addCase(fetchBetHistoryDetailsThunk.pending, (state, action) => {
        return {
          ...state,
          transactionLoader: true
        }
      })
      .addCase(fetchBetHistoryDetailsThunk.rejected, (state, action) => {
        return {
          ...state,
          betHistoryDetailsData: action.payload,
          transactionLoader: false
        }
      })
      .addCase(fetchSportsTransactionReportThunk.fulfilled, (state, action) => {
        return {
          ...state,
          sportsTransactionReportData: action?.payload?.sportsTransaction,
          transactionLoader: false
        }
      })
      .addCase(fetchSportsTransactionReportThunk.pending, (state, action) => {
        return {
          ...state,
          transactionLoader: true
        }
      })
      .addCase(fetchSportsTransactionReportThunk.rejected, (state, action) => {
        return {
          ...state,
          sportsTransactionReportData: action.payload,
          transactionLoader: false
        }
      })
      .addCase(fetchBonusDetailsThunk.fulfilled, (state, action) => {
        return {
          ...state,
          bonusReportsDetailsData: action?.payload?.userBonus,
          transactionLoader: false
        }
      })
      .addCase(fetchBonusDetailsThunk.pending, (state, action) => {
        return {
          ...state,
          transactionLoader: true
        }
      })
      .addCase(fetchBonusDetailsThunk.rejected, (state, action) => {
        return {
          ...state,
          bonusReportsDetailsData: action.payload,
          transactionLoader: false
        }
      })
      .addCase(fetchBonusClaimThunk.fulfilled, (state, action) => {
        return {
          ...state,
          bonusClaimLoader: false
        }
      })
      .addCase(fetchBonusClaimThunk.pending, (state, action) => {
        return {
          ...state,
          bonusClaimLoader: true
        }
      })
      .addCase(fetchBonusClaimThunk.rejected, (state, action) => {
        return {
          ...state,
          betHistoryDetailsData: action.payload,
          bonusClaimLoader: false
        }
      })
  }
})

export default transactionSlice.reducer
