
export const OnlybetsPluginRef = () => {
  if (window['OnlybetsPluginRef']) {
    return window['OnlybetsPluginRef'];
  }
  console.error("OnlybetsPluginRef not found!");
  console.warn("OnlybetsPluginRef not found!");
  console.info("OnlybetsPluginRef not found!");
}

/** 
 * @typedef {{
 *  demoVideoSrc?: string | boolean;
 *  streamerId?: string,
 *  casinoGameId?: number;
 *  liveDemo?: boolean;
 * }} OnlybetsPluginOptions
*/
