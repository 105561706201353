import { METHOD_TYPES, LOADER_HANDLER_TYPES } from 'constants/index'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'


export const fetchTransactionReportsDetailsService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/user/get-user-transactions', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}
export const fetchBetHistoryDetailsService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/casino/transactions', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}

export const fetchSportsTransactionReportService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/sports/transactions', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}

/** @type {(payload: { pageNo: string, limit: string, startDate: string, endDate: string }) => Promise} */
export const getUserBonusService = (payload) => {
  payload = { ...payload, pageNo: 1, limit: 15 };
  return axiosInstanceService(METHOD_TYPES.get, '/bonus/get-user-bonus', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}
export const fetchBonusClaimService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/bonus/claim', payload, {
    server: microServices.SERVICE_URL_1,
    loader: false,
    showApiErrorMessage: false
  })
}
export const fetchBonusActivateService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/bonus/request-bonus', payload, {
    server: microServices.SERVICE_URL_1,
    loader: false,
    showApiErrorMessage: false
  })
}

export const getBonusService = (/** @type {{ bonusType: "deposit" | "joining", visibleInPromotions: boolean }} */ payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/bonus/get-all-bonus', {}, {
    server: microServices.SERVICE_URL_1,
    loader: false,
    showApiErrorMessage: false,
    params: payload,
  })
}
