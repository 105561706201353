import { makeStyles } from '@mui/styles'
export const useNotSigninStyles = makeStyles((theme) => ({
  header: {
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1.5),
  },
  btn: {
    width: "100%",
    maxWidth: theme.spacing(15),
    margin: "auto",
    display: "flex",
    textTransform: "uppercase"
  },
  container: {
    padding: theme.spacing(3, 2),
    "& .MuiTypography-root": {
      color: theme.colors.white
    },
    "& .MuiTypography-h5": {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  btnClose: {
    position: "absolute",
    top: theme.spacing(0.4),
    right: theme.spacing(0.4),
  }
}))
