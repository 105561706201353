import { makeStyles } from '@mui/styles'
import '../../../../assets/css/App.css'

export const useStyles = makeStyles((theme) => ({

  searchGameModal: {
    color: theme.colors.white,
    '& .modalHeading': {
      backgroundColor: theme.colors.themeCard,
      color: theme.colors.white,
    },
    '& .modalBody': {
      // backgroundColor: theme.colors.primaryLightBlue,
      backgroundColor: theme.colors.primaryDarkBlue,
      padding: theme.spacing(0, 0, 0, 0),

    }
  },
}))
