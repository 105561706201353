import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthToken } from "helpers/cookie.helpers";

// import { signIn, signOut } from 'helpers/cookie.helpers'
import {
  fetchGameSubCategoryService,
  fetchGameProviderService,
  initGameService,
  fetchGamesListService,
  initDemoGameService,
  fetchBannerListService,
  fetchGameCategoryService,
  addTofavoriteGameService,
  deleteTofavoriteGameService,
  fetchTofavoriteGamesService,
  fetchFavoriteGameByIdService,
} from "network/services/casino.services";
// import i18n from 'i18next'
// import { ROUTE_PATHS } from 'constants/index'
// import { openErrorToaster } from 'helpers/toaster.helpers'
// import { handleLanguageChange } from 'redux-thunk/redux/user/user.slice'

/**
 * Fetch list of Game category
 */
export const fetchGameCategoryThunk = createAsyncThunk(
  "casino/game-category",
  async ({ payload, navigate }, thunkApi) => {
    try {
      const res = await fetchGameCategoryService();
      const { casinoCategories } = res;
      return casinoCategories;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * Fetch list of Game sub-category
 */
export const fetchGameSubCategoryThunk = createAsyncThunk(
  "casino/game-sub-category",
  async ({ payload, navigate }, thunkApi) => {
    try {
      const res = await fetchGameSubCategoryService(payload);
      const {
        response: { subCategoryList },
      } = res;
      return subCategoryList;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

export const fetchGameSubCategoryMenuThunk = createAsyncThunk(
  "casino/game-sub-category-menu",
  async ({ payload, navigate }, thunkApi) => {
    try {
      const res = await fetchGameSubCategoryService(payload);
      const {
        response: { subCategoryList },
      } = res;
      return subCategoryList;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * Fetch list of Providers
 */
export const fetchGameProvidersThunk = createAsyncThunk(
  "casino/game-provider",
  async (payload, thunkApi) => {
    try {
      const res = await fetchGameProviderService(payload);
      return res?.providers;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * Fetch Provider Games
 */
export const fetchProviderGamesThunk = createAsyncThunk(
  "casino/provider-games",
  async ({ providerId, limit = 15, pageNo = 1 }, thunkApi) => {
    try {
      const res = await fetchGamesListService({
        providerId: [providerId],
        subCategoryId: "all",
        limit,
        pageNo,
      });
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * Init game start
 */
export const initGameStartThunk = createAsyncThunk(
  "casino/init-game",
  async ({ payload }, thunkApi) => {
    try {
      if (payload?.demo) {
        const res = await initDemoGameService(payload);
        return res;
      } else {
        const res = await initGameService(payload);
        return res;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * Fetch list of games
 */
export const fetchGamesThunk = createAsyncThunk(
  "casino/games",
  async ({ payload }, thunkApi) => {
    try {
      const res = await fetchGamesListService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * Fetch list of games for search
 */
export const fetchSearchedGamesThunk = createAsyncThunk(
  "casino/searchedGames",
  async ({ payload }, thunkApi) => {
    try {
      const res = await fetchGamesListService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

export const fetchBannerThunk = createAsyncThunk(
  "casino/banner",
  async ({ payload }, thunkApi) => {
    try {
      const res = await fetchBannerListService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);
export const addTofavoriteGameThunk = createAsyncThunk(
  "/casino/add-favorite",
  async (payload, thunkApi) => {
    try {
      const res = await addTofavoriteGameService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);
export const deleteTofavoriteGameThunk = createAsyncThunk(
  "/casino/delete-favorite",
  async (payload, thunkApi) => {
    try {
      const res = await deleteTofavoriteGameService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);
export const fetchFavoriteGamesThunk = createAsyncThunk(
  "/casino/get-favorite",
  async (payload, thunkApi) => {
    try {
      const res = await fetchTofavoriteGamesService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);
export const fetchFavoriteGameByIdThunk = createAsyncThunk(
  "/casino/get-favorite-by-id",
  async (payload, thunkApi) => {
    try {
      const res = await fetchFavoriteGameByIdService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);
