import CircularProgress from "@mui/material/CircularProgress";
import { Button as MuiButton } from "../../../node_modules/@mui/material/index";
import useStyles from "./Button.styles";
import { useTranslation } from "react-i18next";
import classNames from "../../../node_modules/classnames/index";

const Button = ({ children, loading, disabled, t, variant = "secondary", outline, className, icon, brightness, ...props }) => {
  const classes = useStyles();
  const { t: translation } = useTranslation();

  return (<>
    <MuiButton
      type="button"
      variant="contained"
      className={classNames(classes.btn, className, outline ? "outline" : null, variant, loading ? "loading" : null)}
      data-icon={icon ?? undefined}
      disabled={loading || disabled}
      data-brightness={brightness ?? undefined}
      {...props}
    >
      {loading ?
        <div className="loader">
          <CircularProgress
            size={24}
            sx={{ color: "#ffffff" }}
          />
        </div>
        : null
      }
      <span className="text">{t ? translation(t) : children}</span>
    </MuiButton>
  </>)
}

export default Button;