
import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  globalPagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButtonBase-root': {
      color: theme.colors.white,
      '&.Mui-selected': {
        background: theme.colors.footerBg
      }
    }
  }
}))


export const usePaginationStyles =  makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(0.5),
  },

    btnPrimary: {
      background: theme.colors.btnPrimary,
      color: theme.colors.white,
      fontSize: theme.spacing(0.875),
      fontWeight: theme.typography.fontWeightSemiBold,
      marginRight: theme.spacing(0.625),
      padding: theme.spacing(0, 1),
      borderRadius: theme.spacing(0.25),
      [theme.breakpoints.down('md')]: {
        minWidth: 'auto'
      },
      border: theme.border.primaryBorder,
  
      '&:hover': {
        background: theme.colors.btnSecondary,
        color: theme.colors.white
      }
    },
    btnSecondary: {
      background: theme.colors.btnSecondary,
      color: theme.colors.tableEvenBg,
      fontWeight: theme.typography.fontWeightSemiBold,
      padding: theme.spacing(0, 1),
      borderRadius: theme.spacing(0.25),
      [theme.breakpoints.down('md')]: {
        minWidth: 'auto'
      },
      '&:hover': {
        background: theme.colors.btnSecondaryHover,
        color: theme.colors.white
      }
    }
  
  }))
  