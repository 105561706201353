import { createSlice } from "@reduxjs/toolkit";

import { LANGUAGES } from "constants/index";
import {
  getLocalLanguage,
  setLocalLanguage,
} from "helpers/localstorage.helpers";
import {
  fetchUserInformationThunk,
  userLoginThunk,
  signUpNewUser,
  createAffiliateThunk,
  getAvailableCurrencies,
  depositAmountThunk,
  fetchUserWithdrawRequestsThunk,
  validateUserReCaptchaToken,
  claimCoinsThunk,
  fetchDocumentLabelThunk,
  uploadKYCDocumentLabelThunk,
  fetchUploadedKYCDocumentsLabelThunk,
  forgotPasswordThunk,
  resetPasswordThunk,
  updateUserProfilePasswordThunk,
  updateUserProfile,
  getSportsbookToken,
  updateLanguageThunk,
  userLogoutThunk,
} from "redux-thunk/thunk/user/user.thunk";
import { useSelector } from "react-redux";

const defaultUserState = {
  userInformationData: null,
  userInformationDataLoader: true,
  language: getLocalLanguage() || LANGUAGES[0].languageCode,
  userData: null,
  payments: { currencies: [], address: "", qr: "", withdrawRequests: [] },
  logoutDialog: false,
  shouldShowRegistrationCompleteDialog: false,
  claimNowData: null,
  claimNowLoader: false,
  fetchDocumentLabelList: [],
  fetchDocumentLabelloader: false,
  uploadKYCDocumentLabelData: null,
  uploadKYCDocumentLabelLoader: false,
  fetchUploadedKYCDocumentsLabelList: [],
  fetchUploadedKYCDocumentsLabelLoader: false,
  forgetPasswordData: null,
  resetPasswordData: null,
  loader: false,
  isLoggedIn: false,
  updateUserProfilePassword: null,
  updateUserProfileData: null,
  updateUserProfileLoader: false,
  sportsbookToken: null,
  isUserLogout: false,
  withdrawRequestsFetched: false,
  requiredOtp: false,
  otpCodeExpired: false,
};

const userSlice = createSlice({
  name: "userSlice",
  initialState: defaultUserState,
  reducers: {
    handleLanguageChange: (state, action) => {
      setLocalLanguage(action.payload);
      window.location.reload();
      return {
        ...state,
        language: action.payload,
      };
    },
    handleBalanceChange: (state, action) => {
      const totalAmount = action.payload.balance;
      const amount = action.payload.amount;
      const nonCashAmount = action.payload.nonCashAmount;

      const userWallet = {
        ...state.userInformationData.userWallet,
        totalAmount,
        ...(amount || amount >= 0 ? { amount } : {}),
        ...(nonCashAmount || nonCashAmount >= 0 ? { nonCashAmount } : {}),
      };
      return {
        ...state,
        userInformationData: { ...state.userInformationData, userWallet },
      };
    },
    handleLoyaltyDetalsChange: (state, action) => {
      const userLoyalty = {
        ...action.payload,
      };
      return {
        ...state,
        userInformationData: { ...state.userInformationData, ...userLoyalty },
      };
    },
    handelLogoutDialog: (state, action) => {
      return {
        ...state,
        logoutDialog: action.payload.show,
      };
    },
    handleRegistrationCompleteDialog: (state, action) => {
      return {
        ...state,
        shouldShowRegistrationCompleteDialog: action.payload,
      };
    },
    setLoggedIn: (state, action) => {
      return {
        ...state,
        isLoggedIn: action?.payload?.isLoggedIn,
      };
    },
    SetUpdateUserProfileData: (state, action) => {
      return {
        ...state,
        updateUserProfileData: action?.payload,
      };
    },
    SetUpdateUserProfilePassword: (state, action) => {
      return {
        ...state,
        updateUserProfilePassword: action?.payload,
      };
    },
    setSportsbookToken: (state, action) => {
      return { ...state, sportsbookToken: action?.payload }
    },
    setSignUpNewUser: (state, action) => {
      return { ...state, userInformationData: action.payload }
    },
    setUserLoginThunk: (state, action) => {
      return { ...state, userData: action.payload, }
    },
    updateUserInformationDataThunk: (state, action) => {
      return { ...state, userInformationData: { ...state.userInformationData, ...action.payload }, }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInformationThunk.fulfilled, (state, action) => {
        return {
          ...state,
          userInformationDataLoader: false,
          userInformationData: action.payload?.getUser
        };
      })
      .addCase(fetchUserInformationThunk.pending, (state, { meta }) => {
        const { arg } = meta;

        return {
          ...state,
          userInformationDataLoader: arg && typeof arg == "object" && arg.hasOwnProperty('userInformationDataLoader') ? arg.userInformationDataLoader : true,
        };
      })
      .addCase(fetchUserInformationThunk.rejected, (state, action) => {
        return {
          ...state,
          userInformationDataLoader: false,
        };
      })
      .addCase(signUpNewUser.fulfilled, (state, action) => {
        return {
          ...state,
          userInformationData: action.payload,
          loader: false,
        };
      })
      .addCase(signUpNewUser.rejected, (state, action) => {
        return {
          ...state,
          userInformationData: action.payload,
          loader: false,
        };
      })
      .addCase(signUpNewUser.pending, (state, action) => {
        return {
          ...state,
          loader: true,
        };
      })
      .addCase(userLoginThunk.fulfilled, (state, action) => {
        return {
          ...state,
          userData: action.payload,
          loader: false,
          isLoggedIn: true,
          requiredOtp: action.payload.requiredOtp,
          otpCodeExpired: action?.payload?.otpCodeExpired,
        };
      })
      .addCase(userLoginThunk.rejected, (state, action) => {
        return {
          ...state,
          userData: action.payload,
          otpCodeExpired: action?.payload?.otpCodeExpired,
          loader: false,
        };
      })
      .addCase(userLoginThunk.pending, (state, action) => {
        return {
          ...state,
          loader: true,
        };
      })
      .addCase(createAffiliateThunk.fulfilled, (state, action) => {
        return { ...state };
      })
      .addCase(getAvailableCurrencies.fulfilled, (state, action) => {
        return {
          ...state,
          payments: { ...state.payments, currencies: action.payload },
        };
      })
      .addCase(depositAmountThunk.fulfilled, (state, action) => {
        return {
          ...state,
          payments: {
            ...state.payments,
            address: action.payload.address,
            qr: action.payload.qr,
          },
        };
      })
      .addCase(fetchUserWithdrawRequestsThunk.fulfilled, (state, action) => {
        return {
          ...state,
          payments: { ...state.payments, withdrawRequests: action.payload },
          fetchUserWithdrawRequestsLoader: false,
          withdrawRequestsFetched: true,
        };
      })
      .addCase(fetchUserWithdrawRequestsThunk.pending, (state, action) => {
        return {
          ...state,
          fetchUserWithdrawRequestsLoader: true,
        };
      })
      .addCase(claimCoinsThunk.fulfilled, (state, action) => {
        return {
          ...state,
          claimNowData: action.payload,
          claimNowLoader: false,
        };
      })
      .addCase(claimCoinsThunk.pending, (state, action) => {
        return {
          ...state,
          claimNowLoader: true,
        };
      })
      .addCase(claimCoinsThunk.rejected, (state, action) => {
        return {
          ...state,
          claimNowData: action.payload,
          claimNowLoader: false,
        };
      })
      .addCase(fetchDocumentLabelThunk.fulfilled, (state, action) => {
        return {
          ...state,
          fetchDocumentLabelList: action?.payload?.documentLabel,
          fetchDocumentLabelloader: false,
        };
      })
      .addCase(fetchDocumentLabelThunk.rejected, (state, action) => {
        return {
          ...state,
          fetchDocumentLabelList: action.payload,
          fetchDocumentLabelloader: false,
        };
      })
      .addCase(fetchDocumentLabelThunk.pending, (state, action) => {
        return {
          ...state,
          fetchDocumentLabelloader: true,
        };
      })

      .addCase(uploadKYCDocumentLabelThunk.fulfilled, (state, action) => {
        return {
          ...state,
          uploadKYCDocumentLabelData: action.payload,
          uploadKYCDocumentLabelLoader: false,
        };
      })
      .addCase(uploadKYCDocumentLabelThunk.rejected, (state, action) => {
        return {
          ...state,
          uploadKYCDocumentLabelData: action.payload,
          uploadKYCDocumentLabelLoader: false,
        };
      })
      .addCase(uploadKYCDocumentLabelThunk.pending, (state, action) => {
        return {
          ...state,
          uploadKYCDocumentLabelData: action.payload,
          uploadKYCDocumentLabelLoader: true,
        };
      })

      .addCase(
        fetchUploadedKYCDocumentsLabelThunk.fulfilled,
        (state, action) => {
          return {
            ...state,
            fetchUploadedKYCDocumentsLabelList: action.payload,
            fetchUploadedKYCDocumentsLabelLoader: false,
          };
        }
      )
      .addCase(
        fetchUploadedKYCDocumentsLabelThunk.rejected,
        (state, action) => {
          return {
            ...state,
            fetchUploadedKYCDocumentsLabelList: action.payload,
            fetchUploadedKYCDocumentsLabelLoader: false,
          };
        }
      )
      .addCase(fetchUploadedKYCDocumentsLabelThunk.pending, (state, action) => {
        return {
          ...state,
          fetchUploadedKYCDocumentsLabelLoader: true,
        };
      })

      .addCase(forgotPasswordThunk.fulfilled, (state, action) => {
        return {
          ...state,
          forgetPasswordData: action.payload?.forgetPasswordEmailSent,
          loader: false,
        };
      })

      .addCase(forgotPasswordThunk.rejected, (state, action) => {
        return {
          ...state,
          forgetPasswordData: action.payload,
          loader: false,
        };
      })

      .addCase(forgotPasswordThunk.pending, (state, action) => {
        return {
          ...state,
          loader: true,
        };
      })
      .addCase(resetPasswordThunk.fulfilled, (state, action) => {
        return {
          ...state,
          resetPasswordData: action.payload,
          loader: false,
        };
      })

      .addCase(resetPasswordThunk.rejected, (state, action) => {
        return {
          ...state,
          resetPasswordData: action?.payload,
          loader: false,
        };
      })

      .addCase(resetPasswordThunk.pending, (state, action) => {
        return {
          ...state,
          loader: true,
        };
      })
      .addCase(updateUserProfilePasswordThunk.fulfilled, (state, action) => {
        return {
          ...state,
          updateUserProfilePassword: action.payload,
          loader: false,
        };
      })

      .addCase(updateUserProfilePasswordThunk.rejected, (state, action) => {
        return {
          ...state,
          updateUserProfilePassword: action?.payload,
          loader: false,
        };
      })

      .addCase(updateUserProfilePasswordThunk.pending, (state, action) => {
        return {
          ...state,
          loader: true,
        };
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        return {
          ...state,
          updateUserProfileData: action.payload,
          updateUserProfileLoader: false,
        };
      })

      .addCase(updateUserProfile.rejected, (state, action) => {
        return {
          ...state,
          updateUserProfileData: action?.payload,
          updateUserProfileLoader: false,
        };
      })

      .addCase(updateUserProfile.pending, (state, action) => {
        return {
          ...state,
          updateUserProfileLoader: true,
        };
      })

      .addCase(getSportsbookToken.fulfilled, (state, action) => {
        return {
          ...state,
          sportsbookToken: action?.payload?.token,
        };
      })
      .addCase(getSportsbookToken.rejected, (state, action) => {
        return {
          ...state,
          sportsbookToken: null,
        };
      })
      .addCase(updateLanguageThunk.fulfilled, (state, action) => {
        setLocalLanguage(action.payload?.data?.locale?.toLowerCase());
        window.location.reload();
        return {
          ...state,
          language: action.payload?.data?.locale?.toLowerCase(),
        };
      })
      .addCase(updateLanguageThunk.pending, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(updateLanguageThunk.rejected, (state, action) => {
        return {
          ...state,
          // language: action.payload,
        };
      })
      .addCase(userLogoutThunk.fulfilled, (state, action) => {
        return {
          ...state,
          isUserLogout: true,
        };
      })
      .addCase(userLogoutThunk.pending, (state, action) => {
        return {
          ...state,
          isUserLogout: action.payload,
        };
      })
      .addCase(userLogoutThunk.rejected, (state, action) => {
        return {
          ...state,
          isUserLogout: false,
        };
      })
  },
});

export const {
  handleUserNameChange,
  handleLanguageChange,
  handleBalanceChange,
  handleLoyaltyDetalsChange,
  handelLogoutDialog,
  handleRegistrationCompleteDialog,
  setLoggedIn,
  SetUpdateUserProfileData,
  SetUpdateUserProfilePassword,
  setSportsbookToken,
  setSignUpNewUser,
  setUserLoginThunk,
  updateUserInformationDataThunk,
} = userSlice.actions;

export default userSlice.reducer;

export const useUserState = (useSelector) => useSelector((state) => state.user);
