import React, { useEffect, useRef, useState } from "react";
import useStyles from "./styles";

import CloseIcon from '@mui/icons-material/Close';
import { getAuthToken } from "helpers/cookie.helpers";
import useLocalStorage from "hooks/useLocalStorage";
import { useSelector } from "react-redux";

export const PosRegistrationVideoFloat = () => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const { existsStorage, setStorage } = useLocalStorage();
  const { userInformationData } = useSelector((state) => state.user);
  const posRegistrationVideoRef = useRef(null);

  const close = () => {
    setShow(false);
    setStorage("posRegistrationVideo:viewed", true);
  }

  useEffect(() => {
    if (getAuthToken() && !existsStorage("posRegistrationVideo:viewed")) {
      setShow(true);
      if (window['OnlybetsPluginRef']) {
        window['OnlybetsPluginRef'].on("click:home:on", () => {
          setShow(false);
        });
      }
    }
  }, [userInformationData]);

  return false ? (
    <div className={classes.posRegistrationVideoFloatWrapper}>
      <div className="prv-box-shadow">
        <div className="prv-box">
          <div className="prv-header">
            <span className="prv-icon" onClick={close}>
              <CloseIcon />
            </span>
          </div>
          <video ref={posRegistrationVideoRef} src={"https://only-bts-prod-active-storage.s3.amazonaws.com/production/home-video/video-pos-cadastro.mp4"} controls autoPlay playsInline loop />
        </div>
      </div>
    </div>
  ) : null
};

export default PosRegistrationVideoFloat;