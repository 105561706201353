import { useSelector } from "react-redux";

export const useSiteSettings = () => {
  /** @type {{ settings: SiteSettings }} */
  const { settings } = useSelector((state) => state.site);
  return settings;
};

/** 
 * @typedef {{
 *  url: string
 *  logo: string
 *  name: string
 *  sport: boolean
 *  casino: boolean
 *  sports: boolean
 *  languages: Languages
 *  maintenance: boolean
 *  liveDemoTime: number
 *  supportEmail: string
 *  minimumAmountToBetGames: number
 *  enablePlayinNowCasinoPage: boolean
 *  minimumAmountToAccessGames: number
 *  minimumAmountToAccessLives: number
 *  minimumAmountToAccessGamesAndLives: number 
 * }} SiteSettings
 * */