
import { styled } from "@mui/material";

export const LayoutWrapper = styled("div")(
  () => ({
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    minHeight: "100dvh",
  })
);