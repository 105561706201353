import React from "react";
import useStyles from "./styles";
import { Container, Grid } from "../../../node_modules/@mui/material/index";

import OnlybetsLogoColored from '../../assets/images/onlybets-logo-colored.svg';
import OnlybetsWebsiteDesktop from '../../assets/images/onlybets-website-desktop.png';
import OnlybetsWebsiteMobile from '../../assets/images/onlybets-website-mobile.png';
import { useTranslation } from "react-i18next";

export const MaintenancePage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid className={classes.wrapper}>
      <Container maxWidth="xl">
        <Grid Container>
          <Grid item lg={6} md={8} xs={12}>
            <img src={OnlybetsLogoColored} className="onlybets-logo" alt="Onlybets" />
            <h1> {t("maintenanceTitle")} </h1>
            <p> {t("maintenanceDescription")} </p>
          </Grid>
        </Grid>

      </Container>
      <img src={OnlybetsWebsiteMobile} className="OnlybetsWebsiteMobile" />
      <img src={OnlybetsWebsiteDesktop} className="OnlybetsWebsiteDesktop" />
      <div className="circular-lines">
        <span className="line-1"></span>
        <span className="line-2"></span>
      </div>
      <div className="background-blur-color"></div>
    </Grid>
  );
};

export default MaintenancePage;