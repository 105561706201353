import { useEffect } from 'react'
import { USER_WALLET_BALANCE } from '../event-constants/demo/index'
import createSocketConnection from '../socket-connection/index'
import config from 'config/app.config'
import { getAuthToken } from 'helpers/cookie.helpers'

import { WALLET_NAMESPACE } from '../namespaces/index';

const useUserWalletNamespace = () => {
  let socketInstance = null
  const initializeSocketConnection = () => {
    const authToken = getAuthToken()
    if (authToken) {
      socketInstance = createSocketConnection(`${config.SOCKET_URL.replace(/\/+$/, '')}${WALLET_NAMESPACE}`, authToken)
    }
  }

  const listenBalanceUpdateEvent = (cb) => {
    if (socketInstance && cb) {
      socketInstance?.on(USER_WALLET_BALANCE, cb)
      return () => {
        socketInstance.off(USER_WALLET_BALANCE, cb)
      }
    }
  }

  useEffect(() => {
    initializeSocketConnection()
  }, [])

  return {
    listenBalanceUpdateEvent
  }
}

export default useUserWalletNamespace
