/**A Helper to format number */
class FormatNumber {
  /**Format number to social media style
   * ```js
   * FormatNumber.toSocialMedia(123400) // 123.4K
   * ```
   */
  static toSocialMedia = (number) => {
    const formattedNumber = Intl.NumberFormat('en', {
      notation: 'compact',
      roundingMode: 'floor'
    }).format(number);
    return formattedNumber;
  }

  static toLongNumber(number) {
    return Intl.NumberFormat('pt').format(number)
  }

  static currency(/** @type { number }*/value, /** @type { 'BRL' | 'USD' }*/ currency = 'BRL') {
    const locales = {
      BRL: 'pt-BR',
      USD: 'en-UR',
    }
    return new Intl.NumberFormat(locales[currency], {
      style: 'currency',
      currency,
    }).format(value);
  }

  static toInt(number) {
    if (Number.isNaN(number)) return number;
    if (String(number).length && String(number).startsWith(0)) number = Number(String(number).replace('0', ''));
    return parseInt(number) || 0;
  }
}

export default FormatNumber
