import { makeStyles } from '@mui/styles'

const useDiscoverAllStyles = makeStyles((theme) => ({
  themeCardWrap: {
    padding: theme.spacing(0, 0.313),
    transition: '0.15s ease-out 0s',
    '&:hover': {
      transform: 'translateY(-8px)'
    }
  },
  tryForFree: {
    color: theme.colors.white,
    display: 'none',
    position: 'absolute',
    bottom: theme.spacing(1),
    textAlign: 'center',
    width: '100%',
    fontWeight: 600,
    zIndex: '1'
  },
  cardOverlayContent: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%',
    zIndex: '2',
    display: 'none',
    background: 'transparent',
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
    borderRadius: "50%",
    transition: 'ease .2s',
    '& svg': {
      height: theme.spacing(4.5),
      width: theme.spacing(4.5),
      '& path': {
        "&:nth-child(2)": {
          transition: 'ease .2s',
        }
      },
      '&:hover': {
        '& path': {
          '&:nth-child(2)': {
            fill: theme.colors.tableEvenBg
          }
        }
      }
    },
    '&:hover': {
      backgroundColor: theme.colors.btnSecondary,
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
      '& svg': {
        height: theme.spacing(2.5),
        width: theme.spacing(2.5),
      }
    }
  },
  casinoImgWrap: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
    // margin: theme.spacing(0.25, 0),
    borderRadius: theme.spacing(0.5),
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'all 500ms ease-in-out',
    '&:before': {
      background: theme.colors.overlayBg,
      height: '100%',
      width: '100%',
      color: theme.colors.white,
      position: 'absolute',
      zIndex: 1,
      content: '" "',
      display: 'none'
    },
    '& > img': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      cursor: 'pointer'
    },
    '&:hover': {
      '&:before': {
        display: 'block'
      },
      '& > .MuiGrid-root': {
        display: 'block'
      },

      '& > .MuiTypography-root': {
        display: 'block'
      }
    }
  },
  discoverAll: {
    '& .casinoImgWrapskele': {
      background: theme.colors.discoverAllBg,
      paddingBottom: '113% !important',
      '& .discoverText': {
        zIndex: '9',
        color: theme.colors.white,
        fontWeight: '700',
        width: '100%',
        textAlign: 'center',
        padding: theme.spacing(0.313, 0.5),
        textTransform: 'capitalize',
        position: 'relative',
        top: '2.125rem'
      },

      '& .tryForFree': {
        display: 'block !important',
        bottom: '2rem !important',
        fontWeight: '400 !important',
        fontSize: theme.spacing(0.875),
        textTransform: 'capitalize'
      }
    },
    '& .casinoImgWrap': {
      background: theme.colors.discoverAllBg,
      height: '100%',

      '& .discoverText': {
        zIndex: '9',
        color: theme.colors.white,
        fontWeight: '700',
        width: '100%',
        textAlign: 'center',
        padding: theme.spacing(0.313, 0.5),
        textTransform: 'capitalize',
        position: 'relative',
        top: theme.spacing(2.125),
        [theme.breakpoints.down('sm')]: {
          top: theme.spacing(0.5),
        }
      },

      '& .tryForFree': {
        display: 'block !important',
        bottom: theme.spacing(2),
        fontWeight: '400 !important',
        fontSize: theme.spacing(0.875),
        textTransform: 'capitalize',
        color: theme.colors.placeHolderColor,
        [theme.breakpoints.down('sm')]: {
          bottom: theme.spacing(1),
        }
      }
    },

    '& .cardOverlayContent': {
      display: 'block !important'
    },
  },
  themeCard: {
    borderRadius: theme.spacing(0.5),
    position: 'relative',
    zIndex: '-1',
    width: 'var(--game-card-width)',
    height: 'var(--game-card-height)',
  }
}))
const useICardsStyles = makeStyles((theme) => ({
  infoBox: {
    textAlign: 'center',
    color: theme.colors.white,
    background: theme.colors.iCardBg,
    padding: theme.spacing(0.7),
    borderRadius: theme.spacing(0.5),
    minWidth: theme.spacing(12.5),
    zIndex: 9,
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('sm')]: {
      minWidth: '170px',
      left: '0.5rem',
    },

    '& .MuiBox-root': {
      display: 'grid',
      gridTemplateColumns: '9rem 9rem',
      alignItems: 'start',
      padding: theme.spacing(0.6, 0.5),
      '& .MuiTypography-h6': {
        fontSize: theme.spacing(0.8),
        color: theme.colors.placeHolderColor,
        textAlign: "left",
      },
      '& .MuiTypography-p': {
        fontSize: theme.spacing(0.8),
        fontWeight: '700',
        maxWidth: '120px',
        textAlign: 'start',
        // overflow: "hidden",
        // whiteSpace: "nowrap",
        // textOverflow: "ellipsis"
      },

      '&:nth-child(odd)': {
        background: theme.colors.iCardOddChildBg,
        borderRadius: theme.spacing(0.3)
      }
    }
  },
  infoBoxPopover: {
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      borderRadius: theme.spacing(0.5),
      overflow: 'visible'
    },
  }
}))

export { useDiscoverAllStyles, useICardsStyles }
