import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

/**
 * Service to get all cms
 */
export const getAllCms = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/cms', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}
