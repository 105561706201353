import { makeStyles } from "@mui/styles";
import { styled, alpha, InputBase } from '@mui/material'
import { he } from 'date-fns/locale';

export default makeStyles((theme) => ({
  headerSearch: {
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down("md")]: {
      backgroundColor: theme.colors.footerBg,
      margin: "0",
      marginTop: theme.spacing(0),
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.colors.primaryDarkBlue,
      borderRadius: theme.spacing(0.25),
      height: theme.spacing(0.5),
      paddingLeft: "calc(1em + 2rem) !important",
      padding: "0",
      minHeight: theme.spacing(2.5),
      paddingRight: "1rem !important",
      [theme.breakpoints.down("md")]: {
        backgroundColor: theme.colors.footerBg,
        width: "100%",
      },
    },
    "& .MuiInputBase-root": {
      [theme.breakpoints.down("md")]: {
        backgroundColor: theme.colors.footerBg,
        width: "100%",
        borderRadius: "5px",
        color: theme.colors.white,
      },
    },
    "& .Mui-focused": {
      [theme.breakpoints.down("md")]: {
        width: "100% !important",
      },
    },
    "& .searchIcon": {
      padding: theme.spacing(0, 1, 0, 1),
      zIndex: "1",
      [theme.breakpoints.down("md")]: {
        color: theme.colors.white,
        left: "5px",
      },
    },
    // [theme.breakpoints.down("md")]: {
    //   width: "50px",
    //   marginRight: "20px",
    // },
  },

  searchGameModal: {
    // filter: 'blur(10px)',
    color: theme.colors.white,
    "& .modalHeading": {
      backgroundColor: theme.colors.themeCard,
      color: theme.colors.white,
    },
    "& .MuiDialog-paperScrollPaper": {
      borderRadius: theme.spacing(0.9),
      backgroundColor: 'transparent',
      maxHeight: "calc(100% - 250px)",
      maxWidth: "1200px",
      minWidth: "1200px",
      minHeight: "calc(100% - 254px)",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "100%",
        minWidth: "100%",
        maxHeight: "calc(100% - 50px)",
        minHeight: "calc(100% - 50px)",
      },
    },
    "& .modalBody": {
      backgroundColor: theme.colors.primaryLightBlue,
      padding: theme.spacing(0, 0, 0, 0),
      zIndex: "0",
      "& input": {
        width: "-webkit-fill-available",
        height: "40px",
        borderRadius: "8px",
        border: "none",
        color: theme.colors.white,
        fontSize: theme.spacing(1),
        backgroundColor: theme.colors.themeCard,
        padding: "0.5rem 1rem",
        maxHeight: "40px",
        "&:focus": {
          outline: "none",
        },
        "&::placeholder": {
          color: theme.colors.white,
          fontWeight: theme.typography.fontWeightBold
        }
      }
    },
    "& .search-games-container": {
      [theme.breakpoints.down('md')]: {
        flexDirection: "column-reverse",
      },
      "& .infinite-scroll-component": {
        overflow: "visible !important"
      }

    }
  },
  leftSection: {
    backgroundColor: theme.colors.themeCard,
    padding: theme.spacing(1),
    height: "100vh",
    [theme.breakpoints.down('md')]: {
      height: "100%",
      padding: "0",
    },
    "& .custom-accordain-wrap": {
      "& .MuiPaper-root": {
        background: theme.colors.primaryDarkBlue,
        "& .MuiButtonBase-root": {
          padding: theme.spacing(0, 0.625),
          color: theme.colors.white,
          "&.btn-secondary": {
            backgroundColor: theme.colors.themeCard,
            width: "100%",
          },
        },
        "& .MuiSvgIcon-root": {
          color: theme.colors.white,
        },
        "& .MuiTypography-body1": {
          fontSize: theme.spacing(0.875),
        },
      },
      "& .MuiAccordionDetails-root": {
        color: theme.colors.white,
        padding: theme.spacing(0.625),
        fontSize: theme.spacing(0.875),
      },
      "& .search-tags-wrap": {
        "& .MuiButton-root": {
          background: theme.colors.btnSecondary,
          borderRadius: theme.spacing(2),
          color: theme.colors.black,
          minWidth: "auto",
          minHeight: "auto",
          fontSize: theme.spacing(0.75),
          padding: `${theme.spacing(0.313, 0.625)} !important`,
          margin: theme.spacing(0.2),
        },
        "& .search-tags-scroll": {
          maxHeight: "200px",
          overflow: "auto",
        },
      },
    },
    '& .web-category-list': {
      display: 'block',
      [theme.breakpoints.down("md")]: {
        display: 'none',
      },
    }
  },
  rightSection: {
    padding: theme.spacing(0, 0.625),

    "& .search-tags-wrap": {
      margin: theme.spacing(0.625, 0),
      display: "flex",
      flexWrap: "wrap",
      gap: theme.spacing(0.313),
      "& .line-btn": {
        background: "transparent",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: theme.colors.btnSecondary,
        borderRadius: theme.spacing(2),
        padding: theme.spacing(0.313, 1),
        color: theme.colors.white,
        minWidth: 'auto',
        '& .MuiSvgIcon-root': {
          marginLeft: theme.spacing(0.625)
        }

      }
    },
    '& .mob-category-list': {
      display: 'none',
      [theme.breakpoints.down("md")]: {
        display: 'block',
      },
    },

  },

  themeCardWrap: {
    padding: theme.spacing(0, 0.313),
    transition: "0.15s ease-out 0s",
    "&:hover": {
      transform: "translateY(-8px)",
    },
  },
  themeCard: {
    borderRadius: theme.spacing(0.5),
  },
  casinoImgWrap: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
    margin: theme.spacing(0.25, 0),
    borderRadius: theme.spacing(0.5),
    cursor: "pointer",
    overflow: "hidden",
    transition: "all 500ms ease-in-out",
    "&:before": {
      background: theme.colors.overlayBg,
      height: "100%",
      width: "100%",
      color: theme.colors.white,
      position: "absolute",
      zIndex: 1,
      content: '" "',
      display: "none",
    },
    "& > img": {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    "&:hover": {
      "&:before": {
        display: "block",
      },
      "& > .MuiGrid-root": {
        display: "block",
      },
    },
  },
  casinoCardText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: theme.spacing(0.313, 0),
    "& .MuiTypography-h6": {
      color: theme.colors.white,
      fontSize: theme.spacing(0.875),
      fontWeight: theme.typography.fontWeightMedium,
      margin: "0",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: theme.spacing(0.75),
      },
    },
    "& .MuiTypography-body1": {
      color: theme.colors.white,
      fontSize: theme.spacing(0.75),
      fontWeight: theme.typography.fontWeightExtraLight,
    },
    "& a": {
      background: theme.colors.white,
      height: theme.spacing(1),
      width: theme.spacing(1),
      borderRadius: theme.spacing(0.25),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  viewallSection: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(10%, 1fr))",
  },
  noDataImage: {
    width: theme.spacing(15),
    [theme.breakpoints.down("lg")]: {
      width: theme.spacing(10),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100% ",
    },
  },
  searchContainer: {
    position: 'relative',
    width: '100%',
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
    "& .MuiBox-root": {
      width: '100%',
      marginRight: theme.spacing(0.625),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(0.625),
        marginRight: "0",
      },
    },
    "& .MuiButton-root": {
      [theme.breakpoints.down('sm')]: {
        width: "100%",
      },
    }
  },
  input: {
    position: "relative"
  },
  clearButton: {
    position: "absolute",
    top: "50%",
    right: "0.75rem",
    transform: "translateY(-50%)",
    cursor: "pointer",
    color: theme.colors.white,
  },

  btnSecondary: {
    background: theme.colors.btnSecondary,
    color: theme.colors.footerBg,
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.spacing(0.875),
    "&:hover": {
      background: theme.colors.btnSecondaryHover,
      color: theme.colors.white,
    },
    padding: 0,
  },
}));

export const useDidYouKnowStyles = makeStyles((theme) => ({
  noDataImage: {
    width: theme.spacing(15),
    [theme.breakpoints.down("lg")]: {
      width: theme.spacing(10),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100% '
    }
  },
  infoBox: {
    width: '150px',
    bottom: '3rem',
    display: 'none',
    position: 'absolute',
    textAlign: 'center',
    background: theme.colors.iCardBg,
    color: theme.colors.white,
    zIndex: 9,
    left: '0',
    padding: '0.8rem',
    borderRadius: '0.5rem',
    minWidth: '150px',
  },
}))

export const useSearchStyle = makeStyles((theme) => ({
  searchGamesWrap: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  viewAllPageWrap: {
    gridTemplateColumns: "repeat(4, 1fr)",
    zIndex: 0,
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
}));

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "9ch",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));
