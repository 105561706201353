import React, { memo } from 'react'
import {
  Box,
  Typography,
  Popover
} from '@mui/material';
import { useICardsStyles } from './style';

import { v4 as uuidv4 } from 'uuid';

const ICards = (props) => {
  const { renderData, setOpen, anchorEl } = props
  const classes = useICardsStyles()
  return (
    <Popover
      id="mouse-over-popover"
      open={anchorEl}
      anchorEl={anchorEl}
      sx={{
        pointerEvents: 'none',
      }}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: -10,
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        // horizontal: 'left',
      }}
      disableRestoreFocus
      disableScrollLock={true}
      className={`${classes.infoBoxPopover}`}
    >
      <Box className={`${classes.infoBox}`}>
        {Object.keys(renderData)?.map(data => {
          return renderData?.[data] ? (<Box key={uuidv4()}>
            <Typography variant='h6'>
              {data}
            </Typography>
            <Typography variant='p' style={{ textTransform: "capitalize" }}>
              {renderData?.[data]}
            </Typography>
          </Box>) : null
        })}

      </Box>
    </Popover>
  )
}

export default memo(ICards)
