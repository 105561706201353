import React, { forwardRef, memo, useImperativeHandle, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DialogComponent } from 'components/ui-kit/Dialog/index'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/index'
import { useNotSigninStyles } from './style'
import Button from 'components/Button/index'
import { BrandLogoIcon } from 'components/ui-kit/icons/iconComponents/BrandLogoIcon'
import CloseIcon from '@mui/icons-material/Close';


const AccessSubCategoryBonusDialog = forwardRef(({ callback = () => null, ...props }, ref) => {
  const navigate = useNavigate();
  const classes = useNotSigninStyles();
  const [dialogOpen, setDialogOpen] = useState();

  const { t } = useTranslation();

  const closeModal = () => {
    setDialogOpen(false);
  }

  const handleDialogLogin = () => {
    if (callback) callback();
    closeModal();
  }

  const open = () => {
    setDialogOpen(true);
  }

  useImperativeHandle(ref, () => ({ open }));

  return (
    <DialogComponent dialogOpen={dialogOpen}>
      <Grid className={classes.container} container direction='column' alignContent='center'>
        <Button variant='primary' icon className={`${classes.btnClose}`} onClick={closeModal}>
          <CloseIcon />
        </Button>
        <Grid className={classes.header}>
          <BrandLogoIcon />
        </Grid>
        <Grid item xs={6}>
          <Typography align='center'>
            {t('GameNotEligibleForCurrentBonusErrorType')}
          </Typography>
        </Grid>
        <Grid item container justifyContent='center' pt={1.5} pb={1.5}>
          <Grid item xs={12} mb={0}>
            <Button onClick={handleDialogLogin} className={classes.btn}>{t('gotToBonusCategory')}</Button>
          </Grid>
          {/* <Grid item xs={12}>
            <Button variant='tertiary' onClick={handleDialogClose} className={classes.btn}>{t('cancel')}</Button>
          </Grid> */}
        </Grid>
        <Grid item xs={6}>
          <Typography align='center'>
            {t('needHelp')} <a style={{ cursor: "pointer" }} onClick={() => window.Tawk_API?.maximize()}>{t('clickHere')}</a>
          </Typography>
        </Grid>
      </Grid>
    </DialogComponent>
  )
})

export default memo(AccessSubCategoryBonusDialog)
