import PropTypes from 'prop-types'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import ErrorFallbackRender from 'components/layouts/ErrorPage/index'
import AppLoader from 'components/ui-kit/AppLoader/index'
import { LOADER_TYPE } from 'constants/index'
import { getAuthToken } from 'helpers/cookie.helpers'
import { colors } from 'theme/colors/index'
import PrivateRoute from './HOC/PrivateRoutes/index'
import PublicRoute from './HOC/PublicRoutes/index'

const SuspenseWrappedComponent = (Component) => {
  return (
    <Suspense
      fallback={
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          zIndex: 1
        }}
        >
          <AppLoader variant={LOADER_TYPE.SCALE} color={colors.white} />
        </div>
      }
    >
      <ErrorBoundary
        fallbackRender={ErrorFallbackRender}
        onReset={(details) => {
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        <Component />
      </ErrorBoundary>

    </Suspense>
  )
}

const RouteValidator = ({ hasAuth, component, hasNavBar, settings, settingsProperty, notFoundPage, ...otherProps }) => {
  const isAuthenticated = getAuthToken();

  if (!settings || settingsProperty && settings && !settings[settingsProperty]) {
    component = notFoundPage;
  }

  return hasAuth
    ? (
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        Component={SuspenseWrappedComponent(component)}
        hasNavBar={hasNavBar}
      />
    )
    : (
      <PublicRoute
        Component={SuspenseWrappedComponent(component)}
      />
    )
}

RouteValidator.propTypes = {
  component: PropTypes.elementType.isRequired,
  hasAuth: PropTypes.bool,
  path: PropTypes.string
}

export default RouteValidator
