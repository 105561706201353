import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchPaymentProvidersService, depositPixService } from 'network/services/payment.services';


/**
 * Fetch payment providers
 * @typedef { {} } Payload
 * @type { (payload?: { withdrawal?: boolean, deposit?: boolean }) => any }
 */
export const getPaymentProvidersThunk = createAsyncThunk('payments/providers', async ({ payload, navigate }, thunkApi) => {
  try {
    const res = await fetchPaymentProvidersService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})


/**
 * Deposit with PIX
 * @type { (params: {payload?: {amount: number, paymentProviderId: number, currency?: string}}) => any }
 */
export const depositPixThunk = createAsyncThunk('payments/transaction/pix', async ({ payload, navigate }, thunkApi) => {
  try {
    const res = await depositPixService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
