import { createAsyncThunk } from '@reduxjs/toolkit'

import { getSiteInformationSettingsService } from 'network/services/site.service';

/**
 * Fetch Site Information Settings Data Thunk
 */
export const getSiteInformationSettingsThunk = createAsyncThunk('site/settings', async (thunkApi) => {
  try {
    const res = await getSiteInformationSettingsService();
    return res.settings?.SITE_INFORMATION;
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
