// eslint-disable-next-line
export default {
  mustbeAtleast3Characters: '{{label}} doit contenir au moins {{minNumber}} caractères',
  cannotExceed32Characters: '{{label}} ne peut pas dépasser {{maxNumber}} caractères',
  cannotExceed15Characters: '{{label}} ne peut pas dépasser {{maxNumber}} caractères',
  isRequired: '{{label}} est requis',
  invalidEmailFormat: "Format d'email invalide",

  loggedInSuccess: 'Connexion réussie',
  loggedOutSuccess: 'Déconnecté avec succès',
  internalServerError: 'Erreur de serveur interne',
  usernameOrEmailNotMatch: "Nom d'utilisateur/email ou mot de passe ne correspond pas",
  userAlreadyExists: "L'utilisateur existe déjà",
  userNotExists: "L'utilisateur n'existe pas",
  unAuthorized: 'non autorisé',

  discoverAll: 'Descubre todo',
}
