import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({

  posRegistrationVideoFloatWrapper: {
    position: "fixed",
    zIndex: 9998,
    width: theme.spacing(15),
    height: theme.spacing(15),
    padding: theme.spacing(1),
    bottom: 0,
    right: 0,
    boxSizing: "content-box",

    "& video": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },

    "& .prv-box-shadow": {
      width: "100%",
      height: "100%",
      boxShadow: "0px 0px 20px 1px #00000075",
      borderRadius: theme.spacing(1),
    },
    "& .prv-box": {
      position: "relative",
      overflow: "hidden",
      width: "100%",
      height: "100%",
      borderRadius: theme.spacing(1),
    },

    "& .prv-header": {
      position: "absolute",
      top: 0, left: 0,
      width: "100%",
      color: theme.colors.white,
      padding: theme.spacing(0.5),
      background: "rgb(255,255,255)",
      background: "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%)",
      zIndex: 1,
      display: "flex",
      alignItems: "center",
      opacity: 0,
      justifyContent: "end",
      transition: "ease .2s",
      boxSizing: "border-box",

      "& .prv-icon": {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        padding: theme.spacing(0.5),
        borderRadius: "50%",
        "&:hover": {
          background: "#232323a8",
          transition: "ease .2s",
        },
        "& svg": {
          width: theme.spacing(1.1),
          height: theme.spacing(1.1),
        }
      },
    },

    "&:hover": {
      "& .prv-header": {
        opacity: 1,
      },
    },

    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    }
  },

}))
