import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  floatBoxWrapper: {
    position: "fixed",
    backgroundColor: theme.colors.themeCard,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5),
    maxWidth: theme.spacing(50),
    margin: "auto",
    width: "100%",
    left: "50%",
    bottom: theme.spacing(2),
    transform: "translate(-50%, 0)",
    boxShadow: theme.shadows[2],
    zIndex: 999,

    "& .MuiTypography-root": {
      color: theme.colors.white,
      "& a": {
        textDecoration: "underline",
      },
    },
    "& .MuiTypography-h6": {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(.5),
      marginBottom: theme.spacing(.5),
    },

    [theme.breakpoints.down('md')]: {
      width: "95%",
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },

  footer: {
    display: "flex",
    justifyContent: "end",
    gap: theme.spacing(.5),
    marginTop: theme.spacing(1),
  },

  dialog: {
    padding: theme.spacing(2, 2),
    maxWidth: theme.spacing(50),
    "& .MuiTypography-root": {
      color: theme.colors.white,
      "&.MuiTypography-h6": {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(.5),
        marginBottom: theme.spacing(.5),
      },
      "& a": {
        textDecoration: "underline",
      },
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: "none",
      padding: theme.spacing(1.5, 1.5),
    }
  },

  dialogComponent: {
    padding: 0,
    "& .MuiDialog-paper": {
      margin: theme.spacing(1),
    },
  },

  accordionWrapper: {
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(0.5),
      overflow: "hidden",
      marginBottom: theme.spacing(1),
      background: theme.colors.themeCard,
      '& .MuiAccordionDetails-root': {
        background: theme.colors.overlayBg,
        color: theme.colors.white,
        padding: theme.spacing(1),
      },
      '& .MuiAccordionSummary-content': {
        margin: '0',
        width: "100%",
        '& .MuiTypography-root': {
          padding: theme.spacing(1),
          margin: theme.spacing(-1),
          width: "100%",
        },
      },
      '& .MuiAccordionSummary-root': {
        minHeight: '36px',
        padding: theme.spacing(0.5, 1),
        color: theme.colors.white,
        background: theme.colors.themeCard,
      },
      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "none"
      }
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.white
    },
    '& .MuiSwitch-root': {

      '& .MuiSwitch-track': {
        backgroundColor: theme.colors.bodyBg,
      },
      '& .MuiSwitch-switchBase': {
        color: theme.colors.overlayBg,
        "&.Mui-checked": {
          color: theme.colors.btnSecondary,
        },
      },
    },
  },

  dialogFooter: {
    display: "flex",
    gap: theme.spacing(.5),
    margin: theme.spacing(1, 0),
  },

  btnClose: {
    position: "absolute",
    top: theme.spacing(0.4),
    right: theme.spacing(0.4),
  },

}));

