
import config from "config/app.config";
import { useEffect, useState } from "react";


/**
 * 🪝`useLocalStorage` Hook customizado para gerenciar o localstorage
 *
 * ```jsx
 *  import { useLocalStorage } from 'hooks/useLocalStorage'
 *  const { storage, setStorage } = useLocalStorage()
 *
 * ```
 *  @returns
 *  - `storage`: Object
 *  - `setStorage`: (key: PropertyKey, value:string | number) => void
 *  - `removeStorage`: (key: PropertyKey) => void
 *  - `existsStorage`: (key: PropertyKey) => boolean
 *  @props
 *  - `callback`: (storage) => void
 */
const useLocalStorage = (callback = (storage, exists = () => null) => null) => {
  const [storage, set] = useState(() => {
    if (typeof window !== "undefined") {
      const storageValue = localStorage.getItem(config.localStorageName);
      if (storageValue) {
        return JSON.parse(storageValue);
      }
      return {};
    }
  });

  const setLocalStorage = (storage) => {
    set(storage);
    localStorage.setItem(config.localStorageName, JSON.stringify(storage));
  }
  const exists = (key) => {
    return getStorage() ? getStorage()[key] !== undefined : false;
  };

  const getStorage = (key) => {
    const storageValue = localStorage.getItem(config.localStorageName);
    if (storageValue) {
      let store = JSON.parse(storageValue);
      return key ? store[key] : store
    }
    return null;
  };

  const setStorage = (key, value) => {
    let storage = { ...getStorage(), [key]: value };
    setLocalStorage(storage)
  };

  const removeStorage = (key) => {
    let storage = getStorage();
    let newStorage = new Object();

    if (storage) Object.keys(storage)
      .filter((e) => e !== key)
      .forEach((e) => (newStorage[e] = storage[e]));

    setLocalStorage(newStorage)
    return newStorage
  };

  useEffect(() => {
    set(storage);
    callback && callback(storage, exists);
  }, [storage]);

  let res = { storage, setStorage, removeStorage, existsStorage: exists, getStorage };

  return res;
};

export default useLocalStorage;
