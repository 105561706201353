import { styled, Tooltip, tooltipClasses } from '@mui/material'

export const StyledTooltip = styled(({ className = '', ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme, type }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.primaryDarkBlue,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.primaryDarkBlue,
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.35)",
    fontSize: theme.spacing(0.85),
    color: theme.colors.white,
  }
}))
