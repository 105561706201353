
import { makeStyles } from '../../../../node_modules/@mui/styles/index';
import SkeletonImage from '../../../assets/images/onlybetc-default.png';

// import { makeStyles } from '@mui/style'
export default makeStyles((theme) => ({
  gameCardVariables: {
    '--game-card-width': theme.spacing(11.5),
    '--game-card-height': 'calc(var(--game-card-width) * 1.25)',
    '&.game-card-lg': {
      '--game-card-width': theme.spacing(12.5),
    },
    [theme.breakpoints.down('md')]: {
      '&, &.game-card-lg': {
        '--game-card-width': theme.spacing(9.5),
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&, &.game-card-lg': {
        '--game-card-width': theme.spacing(6.5),
      }
    }
  },
  themeCard: {
    borderRadius: theme.spacing(0.5),
    zIndex: '-1',
    position: 'relative',
    width: 'var(--game-card-width)',
    height: 'var(--game-card-height)',
  },
  casinoImgWrap: {
    width: '100%',
    height: '100%',
    position: 'relative',
    borderRadius: theme.spacing(0.5),
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'all 500ms ease-in-out',
    background: `url(${SkeletonImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    '&:before': {
      background: theme.colors.overlayBg,
      height: '100%',
      width: '100%',
      color: theme.colors.white,
      position: 'absolute',
      zIndex: 1,
      content: '" "',
      display: 'none'
    },
    '& > img': {
      position: 'relative',
      top: '0%',
      left: '50%',
      transform: 'translate(-50%, 0%)',
      height: '100%',
      cursor: 'pointer',
      // width: '184px',
      // height: '230px',
      // objectFit:"cover" // need to remove
    },
    '&:hover': {
      '&:before': {
        display: 'block'
      },
      '& > .MuiGrid-root': {
        display: 'block'
      },

      '& > .MuiTypography-root': {
        display: 'block'
      }
    }
  },
  cardOverlayContent: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%',
    zIndex: '2',
    display: 'none',
    background: 'transparent',
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
    borderRadius: "50%",
    transition: 'ease .2s',
    '& svg': {
      height: theme.spacing(4.5),
      width: theme.spacing(4.5),
      '& path': {
        "&:nth-child(2)": {
          transition: 'ease .2s',
        }
      },
      '&:hover': {
        '& path': {
          "&:nth-child(2)": {
            fill: theme.colors.tableEvenBg,
          }
        }
      }
    },
    '&:hover': {
      backgroundColor: theme.colors.btnSecondary,
    }
  },
  themeCardWrap: {
    transition: '0.15s ease-out 0s',
    width: 'max-content',
    margin: 'auto',
    '&.slider-card': {
      padding: theme.spacing(0, 0.4),
    },
    '&:hover': {
      transform: 'translateY(-8px)'
    }
  },
  searchGamesWrap: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    }

  },
  viewAllPageWrap: {
    gridTemplateColumns: 'repeat(8, 1fr)',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    }

  },
  casinoCardText: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(0.313, 0),
    width: 'var(--game-card-width)',
    '& > .MuiGrid-root': {
      // paddingRight: theme.spacing(0.3),
      width: '100%',
    },
    '& .text-truncate': {
      paddingRight: theme.spacing(0.3),
      display: 'grid'
    },
    '& .MuiTypography-h6.game-name': {
      color: theme.colors.white,
      fontSize: theme.spacing(0.875),
      fontWeight: theme.typography.fontWeightMedium,
      margin: '0',
      lineHeight: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(0.75)
      }
    },
    '& .MuiTypography-body1': {
      color: theme.colors.placeHolderColor,
      fontSize: theme.spacing(0.75),
      fontWeight: theme.typography.fontWeightRegular,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& a': {
      background: theme.colors.white,
      height: theme.spacing(1),
      width: theme.spacing(1),
      borderRadius: theme.spacing(0.25),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      transition: 'ease .2s',
      '&:hover .infoBox': {
        display: 'block',
      },
      '&:hover': {
        background: theme.colors.btnPrimary,
        '& svg path': {
          fill: theme.colors.white,
        }
      },
    }
  },
  favIcon: {
    display: 'none',
    position: 'absolute',
    zIndex: 9,
    top: '10px',
    right: '10px',
    '& a': {
      color: theme.colors.white,
      "& .MuiSvgIcon-root": {
        color: theme.colors.white,
      }
    }
  },

  tryForFree: {
    color: theme.colors.white,
    display: 'none',
    position: "absolute",
    bottom: theme.spacing(0.25),
    textAlign: "center",
    width: "100%",
    fontWeight: 600,
    zIndex: '1',
    fontSize: '0.8rem',

  },
  [theme.breakpoints.down('sm')]: {
    width: '80%',
  },
  addedInFav: {
    '& .favourite-outline-icon': {
      color: "white",
    }
  },


  // WINNER CARD
  winnerAmountWrapper: {
    //padding: theme.spacing(0, 0.4),
  },

  winnerAmountContent: {
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    padding: theme.spacing(0.5),
    paddingBottom: theme.spacing(1.3),
    marginBottom: theme.spacing(-1),
    zIndex: -1,
    position: 'relative',
    backgroundColor: theme.colors.themeCard,
    width: 'var(--game-card-width)',

    '& .MuiTypography-h6': {
      color: theme.colors.white,
      lineHeight: 'normal',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(1)
      },
    },
    '& .MuiTypography-body1 ': {
      color: theme.colors.placeHolderColor,
      fontSize: theme.spacing(0.85),
      [theme.breakpoints.down('sm')]: {
        //fontSize: theme.spacing(1)
      },
    }
  }
}))
