import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import axiosInstanceService from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';

/** Return a list from current live streamings or this game
 * @typedef { {streamings: any[], total: number} } StreamingsListResponse
 * @typedef { {casinoGameId?: number, limit?: number, isOnline?: boolean, isOnline?: boolean} } StreamingsListPayload
 * @type { (payload?: StreamingsListPayload) => Promise<StreamingsListResponse> }
 */
export const fetchStreamingsListService = (payload = {}) => {
  const instance = axiosInstanceService(METHOD_TYPES.get, `/stream/streamings?${new URLSearchParams(payload).toString()}`, {}, {
    server: microServices.STREAMING_BASE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
  return instance
}

/** Return iframe src from live streaming
 * @typedef { {streaming: stringd, live_streamings: string} } LaunchIframeResponse
 * @typedef { {username?: string, streamerId?: string, layout?: 'fill', casinoGameId?: string} } LaunchIframePayload
 * @type { (payload?: LaunchIframePayload) => Promise<LaunchIframeResponse> }
 */
export const launchIframeService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, `/stream/launch`, payload, {
    server: microServices.STREAMING_BASE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
