import { createSlice } from '@reduxjs/toolkit';

import { LANGUAGES } from 'constants/index';
import { getLocalLanguage, setLocalLanguage } from 'helpers/localstorage.helpers';
import {
  getPaymentProvidersThunk,
  depositPixThunk,
} from 'redux-thunk/thunk/payment/payment.thunk';

const defaultStreamingState = {
  language: getLocalLanguage() || LANGUAGES[0].languageCode,
  paymentProviders: {
    payment_types: [],
    providers: []
  },
  settings: {},
  depositPix: {
    acquirer_id: null,
    qr_code: '00020126330014BR.GOV.BCB.PIX01116580248106452040000530398654072000.005802BR5904asda6005asdas620705031236304BBC2',
    qr_code_url: null,
    key: null,
  },
  paymentProviderLoading: true,
}

const paymentSlice = createSlice({
  name: 'paymentSlice',
  initialState: defaultStreamingState,
  reducers: {
    handleLanguageChange: (state, action) => {
      setLocalLanguage(action.payload)
      return {
        ...state,
        language: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentProvidersThunk.fulfilled, (state, action) => {
        return {
          ...state,
          paymentProviders: action.payload,
          settings: action.payload?.settings,
          paymentProviderLoading: false,
        }
      })
      .addCase(getPaymentProvidersThunk.pending, (state, action) => {
        return {
          ...state,
          paymentProviderLoading: true,
        };
      })
      .addCase(getPaymentProvidersThunk.rejected, (state, action) => {
        return {
          ...state,
          paymentProviderLoading: false,
        };
      })
      .addCase(depositPixThunk.fulfilled, (state, action) => {
        return {
          ...state,
          depositPix: action.payload
        }
      })
  }
});

export const { handleLanguageChange } = paymentSlice.actions;

export default paymentSlice.reducer;
