// eslint-disable-next-line
export default {
  mustbeAtleast3Characters:
    "{{label}} must be atleast {{minNumber}} characters",
  cannotExceed32Characters: "{{label}} cannot exceed {{maxNumber}} characters",
  cannotExceed15Characters: "{{label}} cannot exceed {{maxNumber}} characters",
  isRequired: "{{label}} is required",
  invalidEmailFormat: "Invalid email format",
  mustbeAtleast3Characters:
    "{{label}} must be atleast {{minNumber}} characters",
  cannotExceed32Characters: "{{label}} cannot exceed {{maxNumber}} characters",
  cannotExceed15Characters: "{{label}} cannot exceed {{maxNumber}} characters",
  isRequired: "{{label}} is required",
  invalidEmailFormat: "Invalid email format",

  loggedInSuccess: "Logged In Successfully",
  loggedOutSuccess: "Logged Out Successfully",
  internalServerError: "Internal Server Error",
  userAlreadyExists: "User already exists",
  userNotExists: "User does not exists",
  unAuthorized: "Unauthorized",
  usernameOrEmailNotMatch: "Username/Email or Password does not match",
  loggedInSuccess: "Logged In Successfully",
  loggedOutSuccess: "Logged Out Successfully",
  internalServerError: "Internal Server Error",
  userAlreadyExists: "User already exists",
  userNotExists: "User does not exists",
  unAuthorized: "Unauthorized",
  usernameOrEmailNotMatch: "Username/Email or Password does not match",

  // Login Page
  usernameOrEmail: "Username/Email",
  username: "Username",
  password: "Password",
  forgotPassword: "Forgot Password?",
  usernameOrEmail: "Username/Email",
  username: "Username",
  password: "Password",
  forgotPassword: "Forgot Password?",

  // Header
  casino: "Casino",
  sportsbook: "Sportsbook",
  promotions: "Promotions",
  winners: "Winners",
  searchGames: "Search games",
  login: "Log In",
  signup: "Sign Up",
  cancel: "Cancel",
  casino: "Casino",
  sportsbook: "Sportsbook",
  promotions: "Promotions",
  winners: "Winners",
  searchGames: "Search games",
  login: "Log In",
  signup: "Sign Up",
  cancel: "Cancel",

  // My Account Dropdown
  balance: "Balance",
  bonus: "Bonus",
  deposit: "Deposit",
  withdraw: "Withdraw",
  withdrawRequest: "Withdraw Request",
  logout: "Logout",
  balance: "Balance",
  bonus: "Bonus",
  withdraw: "Withdraw",
  withdrawRequest: "Withdraw Request",
  logout: "Logout",

  // Reports Dropdown
  transactionReport: "Transaction Report",
  bettingSlipReport: "Betting slip report",
  bettingSlipReport: "Betting slip report",

  // Footer
  category: "Category",
  category: "Category",
  // casino: 'Casino',
  // sportsbook: 'Sportsbook',
  // promotions: 'Promotions',
  support: "Support",
  liveSupport: "Live support",
  supportCenter: "Support Center",
  contactUs: "Contact Us",
  faq: "FAQ",
  tnc: "Terms & Conditions",
  privacyPolicy: "Privacy Policy",
  cookiePolicy: "Cookie Policy",
  bonusRules: "Bonus Rules",
  socialMedia: "Social Media",
  facebook: "Facebook",
  twitter: "Twitter",
  instagram: "Instagram",
  discord: "Discord",
  aboutUs: "About Us",
  aboutOnlybets: "About onlybets",
  affiliates: "Affiliates",
  responsibleGaming: "Responsible Gaming",
  introduction: "Introduction",
  settingLimits: "Setting Limits",
  paymentMethods: "Secure payment methods",
  popularProviders: "Most popular providers",
  copyrightMessage:
    "Copyrights {{year}} All rights reserved. Gambling can be addictive. Please gamble responsibly.",
  support: "Support",
  contactUs: "Contact Us",
  faq: "FAQ",
  tnc: "Terms & Conditions",
  privacyPolicy: "Privacy Policy",
  cookiePolicy: "Cookie Policy",
  bonusRules: "Bonus Rules",
  socialMedia: "Social Media",
  facebook: "Facebook",
  twitter: "Twitter",
  instagram: "Instagram",
  discord: "Discord",
  aboutUs: "About Us",
  aboutOnlybets: "About onlybets",
  affiliates: "Affiliates",
  responsibleGaming: "Responsible Gaming",
  introduction: "Introduction",
  settingLimits: "Setting Limits",
  paymentMethods: "Secure payment methods",
  popularProviders: "Most popular providers",
  copyrightMessage:
    "Copyrights {{year}} All rights reserved. Gambling can be addictive. Please gamble responsibly.",

  // Forgot Password Page
  enterEmail: "Enter your email",
  enterFirstName: "Enter your first name",
  enterLastName: "Enter your last name",
  enterUserName: "Enter your username",
  sendEmail: "Send Email",


  // Signup page
  personalInformation: "Personal Information",
  CPF: "CPF", // 11
  continue: "Continue",
  nameAndSurname: "Name and Surname",
  dateOfBirth: "Date Of Birth",
  goBack: "Go Back",
  email: "E-mail",
  telephone: "Telephone",
  eighteenPlus: "I am over 18 years old",
  acceptTnC: "Terms and conditions | I read and accept", // 11
  personalInformation: "Personal Information",
  CPF: "CPF", // 11
  continue: "Continue",
  nameAndSurname: "Name and Surname",
  dateOfBirth: "Date Of Birth",
  goBack: "Go Back",
  email: "E-mail",
  telephone: "Telephone",
  eighteenPlus: "I am over 18 years old",
  acceptTnC: "Terms and conditions | I read and accept",

  // Profile page
  profile: "Profile",
  name: "Name",
  surname: "Surname",
  gender: "Gender",
  dob: "Date of Birth",
  emailAddress: "Email Address",
  // telephone: 'Telephone'
  language: "Language",
  timeZone: "Time Zone",
  update: "Update",
  confirmPassword: "Confirm Password",
  updatePassword: "Update Password",
  address: "Address",
  residingCounty: "Residing Country",
  residingState: "Residing State",
  residingCity: "Residing City",
  pincode: "Pincode",
  cep: "Zip Code",
  language: "Language",
  timeZone: "Time Zone",
  update: "Update",
  confirmPassword: "Confirm Password",
  updatePassword: "Update Password",
  address: "Address",
  residingCounty: "Residing Country",
  residingState: "Residing State",
  residingCity: "Residing City",
  pincode: "Pincode",
  notinform: "I prefer not to inform",
  other: "Other",
  // User balance page
  userBalance: "User balance",
  bonusBalance: "Bonus Balance",
  insufficientBalance: 'You currently have no balance. Please deposit funds into your Onlybets account to play.',

  // Bonus Page
  activeBonuses: "Active Bonuses",
  fulfilledBonuses: "Fulfilled Bonuses",
  forfeitedBonuses: "Forfeited Bonuses",
  activeBonuses: "Active Bonuses",
  fulfilledBonuses: "Fulfilled Bonuses",
  forfeitedBonuses: "Forfeited Bonuses",

  // Deposit Page
  paymentType: "PAYMENT TYPE",
  cryptoCurrencies: "CRYPTOCURRENCIES",
  depositP1: "Pix is the Brazilian instant payment.",
  depositP2:
    "The payment method created by the Central Bank (BC) in which funds are transferred between accounts in a few seconds, at any time or day.",
  depositP3:
    "It's practical, fast and safe. Pix can be made from a current account, savings account or prepaid payment account.",
  minimum: "Minimum",
  maximum: "Maximum",
  processingTime: "Processing Time 5 minutes",
  value: "value",
  payment_pix: "Payment by pix",
  payment_credit: "Payment by credit card",
  random_key: "Random key",
  key: "Key",
  currency: "Currency",

  copiedSuccessfully: "Copied successfully!",
  cpf: "CPF",
  pix_key_type: "Pix key type",
  pix_key: "Pix Key",
  pix_qr_code: "PIX QR Code",
  pix_code: "PIX Code",
  copy_pix_code: "Copy PIX code",
  expired_qr_code: "Expired QR code!",
  new_qr_code: "New QR code?",
  pix_instructions_title: "Instructions for the PIX code",
  pix_instructions_item_1: "1. Access the pix area in your banking application",
  pix_instructions_item_2:
    "2. Choose the 'Read QR code' option and use your cell phone's camera to scan the code or copy the alternative alphanumeric code (made up of numbers and letters) and paste it into the 'PIX Copia e Cola' option in the application.",
  pix_instructions_item_3: "3. Confirm payment to your game account.",

  successWithdrawalRequest: "Success Withdrawal Request",
  // Withdrawal Request Page
  withdrawalRequest: "WITHDRAWAL REQUEST",
  pixKeyTypeIsRequired: "The pix key type is required",
  invalidCpfNumber: "Invalid CPF number",
  invalidEmail: "Invalid Email",
  invalidPhoneNumber: "Invalid phone number",
  invalidPixRandomKey: "Random pix key is not valid",
  date: "Date",
  time: "Time",
  noResultsFound: "No Results Found",
  search: "Search",
  withdrawalRequest: "WITHDRAWAL REQUEST",
  date: "Date",
  time: "time",
  noResultsFound: "No Results Found",
  search: "Search",

  // Withdrawal Page
  // transactionReport: 'TRANSACTION REPORT',
  knowledgeBase: "Access our knowledge base.",
  withdrawal: "Withdrawal request",
  withdrawalP1: "You cannot make withdrawals to third parties.",
  withdrawalP11: "Withdrawals are only allowed for accounts you own and are also limited to 1 request per day.",

  ERC20Address: "Your ERC20 address",
  withdrawalP2:
    "Please ensure that you do NOT input BEP2, BEP20 (BSC) address as a LTC withdraw address wallets",
  withdrawalP3: "Please do NOT withdraw as cross-chain transfer",
  withdrawalFee: "Withdrawal fee",
  withdrawal: "Withdrawal",
  withdrawalP1:
    "User can not deposit or withdraw fiat currency. Please contact Admin to transact in fiat currency.",
  ERC20Address: "Your ERC20 address",
  withdrawalP2:
    "Please ensure that you do NOT input BEP2, BEP20 (BSC) address as a LTC withdraw address wallets",
  withdrawalP3: "Please do NOT withdraw as cross-chain transfer",
  withdrawalFee: "Withdrawal fee",
  // withdrawalRequest: ''
  amount: "Amount",
  status: "Status",
  amount: "Amount",
  status: "Status",

  // Transaction Report Page
  transactionType: "Transaction Type",
  bettingSlipId: "Betting Slip ID",
  livePreMatch: "Live/Pre-Match",
  bettingSlipState: "Betting Slip State",
  transactionType: "Transaction Type",
  bettingSlipId: "Betting Slip ID",
  livePreMatch: "Live/Pre-Match",
  bettingSlipState: "Betting Slip State",

  // Header
  playingNow: "Playing Now",
  playNow: "Play now",
  loyalty: "Loyalty",
  playingNow: "Playing Now",
  loyalty: "Loyalty",

  // Header Dropdown
  myAccount: "My Account",
  reports: "Reports",
  profile: "Profile",
  myAccount: "My Account",
  reports: "Reports",
  profile: "Profile",

  // "/"

  // Banner 1 Home
  playWithYourFavoriteInfluencer: "Play with your favorite influencer!",
  signUpWith100DollarBonus: "Register now and get up to R$ 100,00 in bonuses on your first deposit.",
  registerNow: "Join us",


  // Banner 2 Home
  welcomePackage: "Welcome Package of up to R$ 100,00",
  ClickToFirstDeposit: "Click the button below and get an unmissable bonus of up to R$ 100,00 on your first deposit.",
  wantBonus: "I want my bonuses",


  // Banner 3 Home
  everyTimeReward: "Every time you play with us, you will be rewarded!",
  knowLoyalty: "Discover our exclusive loyalty program",
  moreInfo: "Know more",



  // Banner 1 Cassino ao vivo
  winWithInfluencer: "Win by playing together with your Favorite Influencer!",
  signUpNowGetBonus: "Sign up now and get an unmissable bonus of up to R$100.00 on your first deposit.",
  registerNow: "Register now!",


  // Banner 2 Cassino ao vivo
  feelCasinoAtmosphere: "Feel the casino atmosphere at home! ",
  exploreLiveGames: "Explore our selection of live games and dive into the fun.",
  wantParticipate: "I want to participate!",


  // Banner 3 Cassino ao vivo
  realOpponents: "Challenge real opponents!",
  livePoker: "Get in on the live poker action and show off your skills.",
  registerNow: "Register now!",



  // Sick_List
  home: "Home",
  games: "Games",
  influencers: "Influencers",
  recommended: "Recommended",
  slots: "Slots",
  jackpots: "Jackpots",
  imLucky: "I'm Lucky",
  home: "Home",
  games: "Games",
  recommended: "Recommended",
  slots: "Slots",
  jackpots: "Jackpots",
  imLucky: "I'm Lucky",

  //= ================END=======================

  // Promotion Page
  readMore: "Read More",
  readMore: "Read More",
  //= ================END=======================

  // winners Page
  recentWinners: "Recent Winners",
  recentLargeMultiplayers: "Recent Large Multiplayers",
  providers: "Providers",
  recentWinners: "Recent Winners",
  recentLargeMultiplayers: "Recent Large Multiplayers",
  providers: "Providers",
  //= ================END=======================

  // deposit page
  pleaseAddMinimum: "* Please Add Minimum",
  pleaseAddMinimum: "* Please Add Minimum",
  //= ================END=======================

  // transaction  page
  filter: "Filter",
  noResultFound: "No Result Found",
  filter: "Filter",
  noResultFound: "No Result Found",
  //= ================END=======================

  // bettingslip
  type: "Type",
  livePreMatch: " Live/Pre-Match:",
  bettingSlipState: "Betting slip state",
  type: "Type",
  livePreMatch: " Live/Pre-Match:",
  bettingSlipState: "Betting slip state",
  //= ================END=======================

  // home page
  yourFirstTime: "Your First Time ?",
  doubleBankroll: "Double Bankroll! | 100 Bonus On Your First Deposit",
  bonusFistTime: "Bonus up to R$1,000",
  descriptionHome: "and play together with your favorite influencers.",
  fistTimeDeposit: ", on your first deposit.",
  textHome: "Make your first deposit ",
  reliableSystem: "Trustworthy Website",
  bonusUpTo1000: "100% Reliable Platform",
  fastAndSecurePayment: "Fast and Secure Payment",
  quickRegistration: "Quick Registration",
  whatOnlybetsOffersYour: "WHAT ONLYBETS OFFERS YOU",
  differentialTreatmentNote:
    "24/7 Support",
  loyaltyProgram: "Programa de Fidelidade",
  quickPayment: "Quick Payment",
  bigPrizes: "High Weekly Prizes",
  playWithInfluencer: "Play with Influencers",
  newWeeklyGames: "New Weekly Games",
  bonusAndCupons: "Bonuses and Coupons at all times",
  almostOnTheSpot: "Almost On The Spot",
  youPayReallyHighAmounts: "You Pay Really High Amounts",
  highReturns: "High Returns",
  newGamesWeekly: "New Games Weekly",
  chooseSomeoneToPlayWithYou: "HAVE SOMEONE TO PLAY WITH YOU!",
  promotion1:
    "At OnlyBets, the main difference is that you are never alone. ",
  promotion2: "The results are much greater when you have someone encouraging you!",
  hereYouAreAPriority: "Here, you are our priority.",
  twentyFourHourSupport: "24-hour support.",
  promotion3:
    "If you have any problems, there will always be someone to help you.",
  othersSpeakForUs: "NOW I'LL LET THE OTHERS SPEAK FOR US",
  betinasAreWaitingForYouComeNow: "BETINAS ARE WAITING FOR YOU COME NOW,",
  promotion4: "The Betinas Are Dying To See You Profit Along With Them",
  weWantYourGood: "WE WANT YOUR GOOD!",
  support24x7: "24/7 SUPPORT",
  weWillGetBackToYouInRecordTime: "We'll Get Back To You In Record Time!",
  languages: "Languages",
  doesOnlybetsOfferFirstDepositBonuses:
    "DOES ONLYBETS OFFER FIRST DEPOSIT BONUSES?",
  firstDepositBonus:
    "Yes! You'll Double Your Bankroll On Your First Deposit, 100% Bonus!",
  isTheWithdrawalFast: "IS THE WITHDRAWAL FAST?",
  withdrawalSpeed:
    "Yes, Our Priority Is To Get You Your Money As Quickly As Possible So That You Can Enjoy It As Soon As Possible.",
  doIHaveAccessToSupport: "DO I HAVE ACCESS TO SUPPORT?",
  supportAccess:
    "You'll Have Access To VIP Support 24 Hours A Day To Answer Your Questions Or Resolve Any Problems You May Have.",
  yourFirstTime: "Your First Time ?",
  doubleBankroll: "Double Bankroll! | 100 Bonus On Your First Deposit",
  reliableSystem: "Reliable System",
  fastAndSecurePayment: "Fast and Secure Payment",
  quickRegistration: "Quick Registration",
  whatOnlybetsOffersYour: "WHAT ONLYBETS OFFERS YOU",
  differentialTreatmentNote:
    "Differential treatment Note 9.6 | in the last 7 days",
  quickPayment: "Quick Payment",
  almostOnTheSpot: "Almost On The Spot",
  youPayReallyHighAmounts: "You Pay Really High Amounts",
  highReturns: "High Returns",
  newGamesWeekly: "New Games Weekly",
  chooseSomeoneToPlayWithYou: "CHOOSE SOMEONE TO PLAY WITH YOU!",
  promotion1:
    "At Onlybets, The Main Difference Is That You Will NEVER Be Alone. Someone Will Always Be Profiting With You, And This Dramatically Increases Your Results...",
  promotion2: "498% Higher Results When You're Playing with a BETINA.",
  hereYouAreAPriority: "Here You Are A Priority.",
  twentyFourHourSupport: "24-Hour Support.",
  promotion3:
    "There Will Always Be Someone To Help You If You Have A Problem, Which Is Usually Very Rare. Here At Onlybets, We Never Deny Help",
  othersSpeakForUs: "NOW I'LL LET THE OTHERS SPEAK FOR US",
  betinasAreWaitingForYouComeNow: "BETINAS ARE WAITING FOR YOU COME NOW,",
  promotion4: "The Betinas Are Dying To See You Profit Along With Them",
  weWantYourGood: "WE WANT YOUR GOOD!",
  support24x7: "24/7 SUPPORT",
  weWillGetBackToYouInRecordTime: "We'll Get Back To You In Record Time!",
  languages: "Languages",
  languagePortuguese: "Portuguese",
  languageEnglish: "English",
  languageSpanish: "Spanish",
  doesOnlybetsOfferFirstDepositBonuses:
    "DOES ONLYBETS OFFER FIRST DEPOSIT BONUSES?",
  firstDepositBonus:
    "Yes! You'll Double Your Bankroll On Your First Deposit, 100% Bonus!",
  isTheWithdrawalFast: "IS THE WITHDRAWAL FAST?",
  withdrawalSpeed:
    "Yes, Our Priority Is To Get You Your Money As Quickly As Possible So That You Can Enjoy It As Soon As Possible.",
  doIHaveAccessToSupport: "DO I HAVE ACCESS TO SUPPORT?",
  supportAccess:
    "You'll Have Access To VIP Support 24 Hours A Day To Answer Your Questions Or Resolve Any Problems You May Have.",
  //= ================END=======================

  //= ================LOGOUT ===================
  areYouSureYouWantToLogout: "Are you sure you want to logout ?",
  yes: "Yes",
  no: "No",
  areYouSureYouWantToLogout: "Are you sure you want to logout ?",
  yes: "Yes",
  no: "No",
  //= ================END=======================

  // register page
  cpfIsRequired: "* CPF Is Required",
  invalidCpf: "Invalid CPF",
  nameIsRequired: "* Name Is Required",
  specialCharactersAreNotAllowed: "* Special characters are not allowed",
  dateOfBirthCannotBeInTheFuture: "* Date of Birth cannot be in the future",
  shouldBeGreaterThanEighteenYears: "* Should be greater than 18 years",
  dateOfBirthIsRequired: "* Date of Birth is Required",
  usernameOrEmailIsRequired: "* Username/Email Is Required",
  invalidEmailAddress: "* Invalid email address",
  emailIsRequired: "* Email Is Required",
  passwordMustBeAtLeastEightCharacters:
    "* Password must be at least 8 characters",
  passwordMustIncludeAtLeastOneUppercaseLowercaseNumber:
    "* Password must include at least one uppercase letter, one lowercase letter, and one number",
  phoneNumberIsNotValid: "* Phone number is not valid",
  phoneNumberIsRequired: "* Phone Number is Required",
  youMustBeEighteenPlus: "* You must be 18+",
  youMustAgreeToTheTermsAndConditions:
    "* You must agree to the terms and conditions",
  reCaptchaShouldBeVerified: "* ReCaptcha should be verified",
  youMustVerifyTheReCaptcha: "* You must verify the recaptcha",
  genderIsRequired: "* Gender is required",
  surnameIsRequired: "* Surname is required",
  usernameIsTooLong: "* Username is too long",
  usernameInvalidCharacters: "* Enter only letters and/or numbers. Spaces and special characters are not allowed.",
  register: "Sign Up!",
  cpfIsRequired: "* CPF Is Required",
  nameIsRequired: "* Name Is Required",
  specialCharactersAreNotAllowed: "* Special characters are not allowed",
  dateOfBirthCannotBeInTheFuture: "* Date of Birth cannot be in the future",
  shouldBeGreaterThanEighteenYears: "* Should be greater than 18 years",
  dateOfBirthIsRequired: "* Date of Birth is Required",
  usernameOrEmailIsRequired: "* Username/Email Is Required",
  invalidEmailAddress: "* Invalid email address",
  emailIsRequired: "* Email Is Required",
  passwordIsRequired: "* Password is Required",
  passwordMustBeAtLeastEightCharacters:
    "* Password must be at least 8 characters",
  passwordMustIncludeAtLeastOneUppercaseLowercaseNumber:
    "* Password must include at least one uppercase letter, one lowercase letter, and one number",
  phoneNumberIsNotValid: "* Phone number is not valid",
  phoneNumberIsRequired: "* Phone Number is Required",
  youMustBeEighteenPlus: "* You must be 18+",
  youMustAgreeToTheTermsAndConditions:
    "* You must agree to the terms and conditions",
  reCaptchaShouldBeVerified: "* ReCaptcha should be verified",
  youMustVerifyTheReCaptcha: "* You must verify the recaptcha",
  genderIsRequired: "* Gender is required",
  surnameIsRequired: "* Surname is required",
  usernameIsTooLong: "* Username is too long",
  usernameIsRequired: "* Username is required",

  register: "Register",
  //= ================END=======================

  // login page

  usernameIsShort: "* Username Is Short!",
  usernameIsShort: "* Username Is Short!",
  // passwordMustBeAtLeastEightCharacters: '*Password must be at least 8 characters',
  // passwordMustIncludeAtLeastOneUppercaseLowercaseNumber: '*Password must include at least one uppercase letter, one lowercase letter, and one number',
  //= ================END=======================

  // Registration Complete PopUp
  registrationCompleteDialogText:
    "We have sent verification link on your registered email account. Please verify your email.",
  ok: "OK",

  // Text to be translate
  categories: "Categories",
  didYouKnow: "Did you know?..",
  categories: "Categories",
  didYouKnow: "Did you know?..",
  youCanUseOneOfMore: `You can use one or more filters in the menu to search for
  games with your favorite feature(s) and/or from your
  favorite provider(s).`,
  gameSearch: "Game Search",
  gameSearch: "Game Search",
  all: "All",
  viewAll: "View All",
  liveCasino: "Live Casino",
  liveCasino: "Live Casino",
  comingSoon: "Coming Soon",
  comingNewUpdate:
    "We're working on something exciting. Stay tuned for updates",
  comingNewUpdate:
    "We're working on something exciting. Stay tuned for updates",
  scrollUp: "Scroll up for more games...",
  loadMore: "load more",

  // loyalty rules
  level: "Level {{number}}",
  points: "Points {{number}}",
  loyaltyPoints: "Loyalty Points {{number}}",
  coins: "Coins {{number}}",
  loyaltyCoins: "Loyalty Coins {{number}}",
  perPoint: "Per Point",

  // loyalty questions
  question1: "How does the OnlyBets loyalty system work",
  answer1:
    "As soon as you play your first spin on OnlyBets, you start earning Loyalty Points and Loyalty Coins. Loyalty Points determine your level, while Loyalty Coins can be exchanged for bonuses. The higher your level, the faster you will earn Loyalty Coins!",
  answer1:
    "As soon as you play your first spin on OnlyBets, you start earning Loyalty Points and Loyalty Coins. Loyalty Points determine your level, while Loyalty Coins can be exchanged for bonuses. The higher your level, the faster you will earn Loyalty Coins!",
  necessoryLoyaltyPoints: "Total Loyalty Points To Reach Next Level",
  loyaltyCoinsEarnedPerLoyaltyPoint: "Loyalty Coins Earned Per Loyalty Point",
  maxLevel: "Max level",
  claimNow: "Claim Now",
  enterValue: "Enter {{value}}",
  isRequired: "{{value}} is required",
  enterValue: "Enter {{value}}",
  isRequired: "{{value}} is required",
  value: "Value",
  valueCantGreaterthan:
    " Enter amount can't greater than your existing {{type}} {{value}} {{currency}}",
  valueCantGreaterthan:
    " Enter amount can't greater than your existing {{type}} {{value}} {{currency}}",
  userLoyalty: "User Loyalty",
  progressionSystem: "Progression system",
  joinNow: "Join Now",
  tryForFree: "Try For Free",
  gameName: "Game Name",
  providerName: "Provider",
  reset: "Reset",
  uploadDocument: "Upload Document",
  documentUploadedSuccessfully: "Document Uploaded Successfully",
  manageYourKyc: "Manage Your KYC",
  canNotUploadAgain: "Once uploaded file, can't upload It again ",
  kycStatus: "Your file is {{status}} by Admin",
  fileAllowed: "Please upload only {{files}} files with allowed size {{size}}",
  fileAllowedFormat: "Please upload only {{files}} files",
  fileAllowedSize:
    "File size exceeds the limit {{fileSize}}. Please upload a smaller file.",
  fileAllowed: "Please upload only {{files}} files with allowed size {{size}}",
  fileAllowedFormat: "Please upload only {{files}} files",
  fileAllowedSize:
    "File size exceeds the limit {{fileSize}}. Please upload a smaller file.",
  categoryName: "Category Name",
  contactWithAdmin: "Please contact with Admin",
  referAFriend: "Refer a friend",
  copyTitleMessage: "Please copy {{title}} to apply",
  yourRefferalCode: "Your Refferal Code",
  refferalCode: "Refferal Code",
  copiedMessage: "your {{text}} copied successfully",
  shareInviteCode: "Share Your Invite Code",
  resetPasswordLinkSentYourRegisterdEmail:
    "Password reset link has been sent to your email",
  resetPasswordLinkIsOnlyValidFor:
    "Password reset link is valid only for {{time}}",
  resetPassword: "Reset Password",
  newPassword: "New Password",
  passwordChangedSuccessfully: "Password changed successfully",
  wantLogin: "Do you want to login",
  tokenExpired: "Your password reset link has expired",
  verifyEmailAgain: "You must verify your email again",
  verifyEmail: "Verify Email",
  userInformation: " User Information",
  youDeserve: "You deserve ",
  ourBrandNew: "our brand-new",
  loyaltySystem: "Loyalty System",
  playGameHandleRest: "Just play the games you love and we'll handle the rest!",
  earnCoinsSpinning: "Earn Coins by spinning",
  earnLoyaltyPointsCoinsOnPlayCasino:
    " Whenever you play in our casino or live casino, you will earn Loyalty Points and Loyalty Coins.",
  alwaysRewardAtOnlybets: "You are always rewarded at OnlyBets",
  onlyBetsWeMakeSureThat: "At OnlyBets we make sure that...",
  everySpinCounts: "...every spin counts!",
  wantPlayerAlwaysRewarded:
    "We want to make sure players are always rewarded when playing at OnlyBets. That is why over",
  perentGameAward: "{{percent}} of our casino games will award you",
  wheneverWinLossLoyaltyPointAndCoins:
    "with Loyalty Points and Loyalty Coins whenever you play, win or lose!",
  lastLoginTime: "Last Login Time",
  loginFirst: "You are not login, Login First",
  prev: "Prev",
  next: "Next",
  page: "Page",
  bonusCode: "Bonus Code",
  wwwOnlybets:
    " www.onlybets.tv   is operated by OnlyBets B.V. (Commercial register of Curacao no. 166128 Emancipatie Boulevard 31, Curacao) under a sublicense CIL pursuant to Master gaming License №5536/JAZ",
  antiMoneyLaundering: "Anti Money Laundering",
  siteUnderDevelopment:
    "The website is in under development. Please visit the page after some time, Thanks",
  earnuptoBRLOnPerInvitation: "Earn up to 50 BRL on per invitation",
  refreshPage: "Refresh page",
  inviteFriendandEarnMoney: "Invite Friend and Earn Money",
  receiveCaseBalanceForEveryFriendYourRefer:
    "Receive case balance for every friend your refer",
  howInvitationWork: "How invitation work", //
  none: "none",
  debit: "Debit",
  credit: "Credit",
  transactionBankingUUID: "Transaction Banking UUID",
  noDataAvailable: "No Data Available",
  casinoTransactionId: "Casino Transaction Id",
  roundId: "Round Id",
  actionType: "Action Type",
  win: "Win",
  lose: "lose",
  live: "live",
  success: "Success",
  pending: "Pending",
  cancelled: "Cancelled",
  failed: "Failed",
  rollBack: "Rollback",
  approved: "Approved",
  rejected: "Rejected",
  active: "Active",
  forfeit: "Lost",
  expired: "Expired",
  claiming: "Claim",
  claimed: "Claimed",
  inprogress: "In progress",
  process: "In progress",
  requested: "Requested",

  removeMoney: "Remove Money",
  addMoney: "Add Money",
  bettingHistoryReport: "Betting History Report",
  others: "Others",
  onlybetsSystem: "Onlybets System",
  uniqueSystemWithVIPSupport:
    "Of course, on one of the sites that has grown the most in recent weeks there would be a affiliate system to make you money. We want to make you profit from a unique system with VIP support.",
  record: "Record",
  Registrationform: "Registration form",
  idNo: "ID No.",
  firstName: " First Name",
  questionRegister: "Tell us what motivates you to become an affiliate of OnlyBets. We're excited to hear what drives you to join us!",
  photoComment: "Upload a photo in good quality and resolution to make your profile stand out.",
  placeHolderQuestionRegister: "Reply with a maximum of 250 characters",
  lastName: "Last Name",
  userName: "User Name",
  email: "Email",
  submit: "Submit",
  AffiliateType: "Affiliate Type",
  Affiliate: "Affiliate",
  Agency: "Agency",
  Streammer: "Streamer",
  howItWorks: "How it works?",
  stepToSuccessFull:
    "It's very simple, just follow the three steps below to be successful",
  step: "Step {{number}}",
  affiliateLinkForOpenAccount: "Open an affiliate account and get your link.",
  promptYourLink: "Promote your link using whatever strategy you want",
  toWinEnjoyEarnings: "To win. Now just enjoy your earnings.",
  weWantTYourGoodness: "WE WANT YOUR GOODNESS!",
  support24_7: "24/7 Support",
  weServeYouInRecordTime: "We serve you in record time!",
  languages: "Languages",
  languageList: "Portuguese, English, Spanish",
  whoAreWe: "WHO ARE WE?",
  ourDetails:
    "We at Onlybets pride ourselves on being open, transparent and fair. We also believe that to be the best you need dedication, effort and motivation. By combining all these aspects, we know that we have one of the most competitive affiliate programs on the market. We've prepared the groundwork with a unique and user-friendly system to make sure both you and the player have the best of everything. Our affiliate managers will show you how to earn players' loyalty and how to get the commissions you want. Our individual approach is guaranteed for all affiliates in our system. We believe that mutual trust and a professional attitude will help us build a beneficial partnership and exceed your expectations.",
  chooseOnlyBets: "Choose OnlyBets",
  highConversionRates: "High conversion rates",
  recordAchievingExcellentConversion:
    "We have a track record of achieving excellent conversion rates with our partners. As we understand the importance of converting clicks into players, we continually analyze and improve our key funnels.",
  exceptionalPlayerValues: "Exceptional Player Values",
  loyaltyBasedRetentionStrategy:
    "Our loyalty-based retention strategy has proven to generate exceptional value throughout a player's lifetime. In other words, more money for you",
  greatCustomerSupport: "Great customer support",
  managersShowEarnPlayerLoyalty:
    "Our dedicated affiliate managers will show you how to earn player loyalty and get the commissions you want. An individual approach is what we focus on with each affiliate. Your success is our priority.",
  immediatePayments: "Immediate payments",
  chooseVariousPaymentMethods:
    "Choose from the various payment methods we offer in the system. Sit back and relax while you wait to receive your monthly commissions as we guarantee fast and secure payments for your convenience.",
  VIPTraining: "VIP Training",
  whatYouWanToWin:
    "We want you to win, and that's why we will deliver exclusive materials and dedicated training to your hands to make you win more!",

  subCategoryName: "Subcategory",
  preferedCurrency: "Prefered Currency",
  preferredCurrencyIsRequired: "Preferred Currency Is Required",
  selectLanguage: "Select Language",
  selectTimezone: "Select Timezone",
  selectCountry: "Select Country",
  kyc: "KYC",
  updateKYC: "Update KYC",
  selectGender: "Select Gender",
  male: "Male",
  feMale: "Female",
  currentPassword: "Current Password",
  enter: "Enter {{text}}",
  isRequired: "{{text}} is Required",
  noSpaceAllow: "Space is not allowed",
  minCharacters: "Min {{characterNo}} is allowed",
  maxCharacters: "Max {{characterNo}} is allowed",
  newPasswordTip:
    "New password must have at least one uppercase letter, one lowercase letter, one number and one special character",
  matchPassword: "Password  should be match",
  passwordChangedSuccessfully: "Password changed successfully",
  updateProfile: "Update Profile",
  profileUpdatedSuccessfully: "Profile updated successfully",
  startAmount: "Start Amount",
  endAmount: "End Amount",
  casinoBet: "Casino Bet",
  casinoWin: "Casino Win",
  applyFilterNote:
    "Note :-To apply filter follow convention like 'Start Date - End Date', 'Start Time - End Time', 'Start Amount - End Amount'",
  bothAreRequired: "*  Start {{text}} - End {{text}} both are required",
  lessThen: "Less than",
  greaterThen: "Greater than",
  dateShouldBeBetween:
    "{{dateType}} should be between {{minDate}} - {{maxDate}}",
  startCantGreaterThanEnd: "Start {{text}} can't greater than End {{text}}",
  startDate: "Start Date",
  endDate: "End Date",
  setOnlyRegistered: "*  Currency can be selected only at the registered time",
  favouriteGames: "Favourite Games",
  sports: "Sports",
  hello: "Hello",
  casinoWelcome: "Casino Welcome Offer",
  joinUsTodayToGetBonus:
    "Join us today and receive a 100% bonus up to R$1,000 to get started at OnlyBets.",
  moreInformation: "More information",
  weeklyTournaments: "Weekly Tournaments",
  immerseYourselfInTheEmbrace:
    "Immerse yourself in the Embrace of Autumn and Collect €5,000 in the Tournament of the Golden Leaves!",
  loyaltyProgram: "Loyalty Program",
  WeWantToEnsureThatPlayers:
    "We want to ensure that players are always rewarded when playing at Onlybets!",
  weekendCashbackBooster: "The weekend cashback booster",
  puttingCashbackYourPocket:
    "We're putting a 15% cashback in your pocket every Monday!",
  topWinners: "Top Winners",
  usernameIsRequired: "* Username Is Required",
  affiliateTypeIsRequired: "* Affiliate type is required",
  casinoWelcomeOffer: "Casino Welcome Offer",
  welcomeBonusAndSpins: " 100% WELCOME BONUS",

  tncListItem1:
    "These rules complement the Bonus Rules and our General Terms.",
  tncListItem2:
    "Promotion valid only for new players who have never made a deposit at the casino.",
  tncListItem3:
    "To receive the bonus from this promotion, simply register and make your deposit.",
  tncListItem4:
    "The bonus for this promotion is 100%.",
  tncListItem5:
    "The maximum bonus value is R$ 1000.00.",
  tncListItem6: "The wagering requirement for this promotion is 35x the bonus balance.",
  tncListItem7:
    "The wagering requirement must be met within 7 days.",
  tncListItem8:
    "If the wagering requirement is not met within the deadline, all bonus funds will be forfeited.",
  tncListItem9:
    "The minimum deposit to qualify is R$1.00.",
  tncListItem10:
    "Promotion valid only once per person, household, shared computer or shared IP address.",
  tncListItem11: "Promotion cannot be combined with other promotions.",
  tncListItem12:
    "OnlyBets reserves the right to restrict access to this promotion to any player for any reason it deems appropriate.",
  tncListItem13:
    "In case of discrepancies between different language versions of these terms, the English version prevails.",
  // tncListItem14: "This promotion cannot be combined with other promotions.",
  // tncListItem15:
  //   "This promotion is not available for players using cryptocurrency wallets.",
  // tncListItem16:
  //   "OnlyBets reserves the right to restrict access to this promotion to any player, for any reason it deems fit.",
  // tncListItem17:
  //   "In case of inconsistencies between the different languages of these terms and conditions, the English version prevails.",

  listItem1:
    "Exceptional Loyalty System: Play and get rewarded for each level advancement.",
  listItem2: "Reload Bonuses and Surprise Gifts: The fun never stops with our special bonuses and gifts.",
  listItem3: "Exclusive VIP Experience: Enjoy unparalleled VIP treatment and exclusive benefits.",
  introText: "Step inside the OnlyBets universe of fun!",
  welcomeOfferText:
    "Start your OnlyBets casino adventure with a spectacular Welcome Offer, tailor-made for champions! Enjoy an incredible 100% Welcome Bonus up to R$1000.00 on your first deposit. Does this sound good to you?",
  moreExcitingText:
    "Great! Because we have a lot more exciting things that will tickle your fancy:",
  tournamentText:
    "If that's not enough, don't worry! Our weekly tournaments, packed with cash prizes, will keep your adrenaline pumping and your entertainment guaranteed!",
  goodLuckText:
    "Why wait? With more than 6,000 games waiting for you, the fun starts now.",
  registerSuccessfull: "You are successfully registered",
  checkMyProgress: "Check My Progress",
  promotionsTournamentsandMore: "Promotions, tournaments and more",
  enhanceYourGamingExperience: "Enhance your gaming experience!",
  subCategoryId: "subCategoryId",
  cashBalance: "Cash Balance",
  totalBalance: "Total Balance",
  inSufficientBalanceToPlay:
    "You do not have sufficient funds at the moment. Please deposit funds into your Blaze account to play",//
  bonusReport: "Bonus Report",
  bonusName: "Bonus Name",
  pending: 'PENDING',
  active: 'ACTIVE',
  cancelled: 'CANCELLED',
  forfeit: 'FORFEITED',
  expired: 'EXPIRED',
  claiming: 'CLAIMING',
  inprogress: 'IN-PROCESS',
  lapsed: 'LAPSED',
  claimed: 'CLAIMED',
  discoverAll: 'Discover all',
  clear: 'Clear',//
  action: "Action",
  amountToWager: "Amount To Wager",
  expiryDate: "Expiry Date",
  BonusCondition: "To transform the bonus into a real balance added to your balance, it is necessary to exceed the amount in bets.",

  EmailNotVerifiedError: "Email Not Verified",
  ThereIsAPendingWithdrawRequestError: "There is already a pending withdrawal request.",
  PaymentProviderError: "An error occurred while finding the payment provider",
  UserIdentificationError: "An error occurred while validating user identification data",
  CpfValidationError: "An error occurred when validating the user's CPF",
  PixCpfMustBeSameRegisteredError: "CPF informed for withdrawal must be the same as registered",
  WalletNotFound: "Wallet not found",
  BalanceError: "Insufficient balance",
  CpfInvalidError: "The CPF number is not valid",
  CpfIrregularError: "CPF number with irregular situation",
  CashOutLimitPerDayError: "Cash out - Limit of transaction is 1 by document per day",
  TransactionRepeatedInLess10MinutesError: "Transaction repeated in less 10 minutes.",
  WithdrawDataError: "An error occurred while generating withdraw data.",
  StreamingApiconnectEconnrefusedError: "Unable to connect to streaming server",
  IncorrectCurrentPasswordErrorType: "Incorrect current password.",

  andGrowing: 'and growing!',
  refund: "Refund",
  bet: "Bet",
  depositBonusClaim: "Deposit bonus claim",
  referralBonusClaim: "Referral bonus claim",
  joiningBonusClaim: "Joining bonus claim",
  joiningBonus: "Joining bonus",
  referralBonus: "Referral bonus",
  depositBonus: "Deposit Bonus",
  pageNotFound: "We can’t find the page you are looking for!",
  goToHome: "Go To Home",
  ooops: "Ooops",
  redirectingMessage: "Redirecting in {{redirectTime}} seconds...",

  volatility: "Volatility",
  themes: "Themes",//
  sportsTransactionReport: "Sports Transaction Report",
  transactionId: "Transaction Id",
  rollback: "Roll back",
  rollbackbeforebetwin: "Rollback before bet/win",
  freespins: "Free spins",
  betInternal: "Bet Internal",
  winInternal: "Win Internal",
  discard: "Discard",
  settlement: "Settlement",
  refund: "Refund",

  CpfCanceledError: "CPF number is canceled",
  CpfNotFoundError: "CPF number not found",
  CpfUnderAgeError: "CPF number is underage",

  UserNameExists: "There is already a registered user with that name",
  UserAlreadyExistErrorType: "There is already a user registered with this email",

  signUpSuccessful: "User registered successfully!",

  CpfAlreadyExists: "There is already a user registered with this CPF number",

  bonusExpiresIn: "Bonus expires in:",
  amountToBeDeposited: "Amount to be deposited:",
  doYouHaveACoupon: "Do you have a coupon?",
  generatePix: "Generate PIX",
  amountMinValue: "Minimum deposit amount is {{minimumDeposit}}",
  maximumDeposit: "Maximum deposit amount is {{maximumWithdraw}}",
  applyBonus: "Apply bonus",
  paymentProvider: "Payment provider",
  scanTheImageToMakePayment: "Scan the image to make payment",

  payment_processed_successfully: "Payment processed successfully!",
  UserAlreadyExists: "User already exists!",

  BonusNotFound: "Bonus Code not found!",
  BonusTypeAlreadyActiveErrorType: "You already have such an active bonus!",

  reffCode: "Reference Code",
  apply: "Apply",

  withdrawRequestRule1: "To make your first withdrawal, you must wager an amount corresponding to 1.7 times the amount of your initial deposit. This criteria must be met to proceed with the withdrawal of funds.",
  withdrawRequestRule2: "Example: After an initial deposit of R$10.00, you must wager a minimum of R$17.00 in casino games or sports betting to release the withdrawal. This requirement is only valid for the first withdrawal.",

  withdrawRequestRule3: "To make your withdrawal, you must wager an amount corresponding to {{rolloverMultiplier}} times the amount of your deposits. This criterion must be met to proceed with the withdrawal of funds.",
  withdrawRequestRule4: "Example: Let's assume that the amount of your deposits was {{depositAmountExample}}. You must wager a minimum of {{rolloverMultiplierAmount}} on casino games or sports betting to release the withdrawal. This requirement applies to the total sum of your deposits.",

  UserNotAllowedWithdrawError: "Withdrawal request not released",
  PixKeyDontBelongToDocumentErrorType: "Pix key does not belong to the user's document",
  FirstDepositAmountLimitError: "The limit for the first deposit must be up to R$ 1,000.00",
  INCORRECT_CREDENTIAL: "This email/password is incorrect",

  minimunMaximunDepositAmount: "Please add at least {{minimumDeposit}} and at most {{maximumDeposit}}",

  wallet: "Wallet",
  viewBalanceInformation: "View balance information",
  maximumWithdrawalAmountExceeded: "Maximum withdrawal amount exceeded",
  minimunMaximunWithdrawAmount: "Please add at least {{minimumWithdraw}} and at most {{maximumWithdraw}}",
  balanceAvailableForWithdrawal: "Real balance",

  CashOutMaxValueIs50000ByDocumentPerDayErrorType: 'Cash out max value is R$ 50.000,00 by document per day',

  claimedSuccessfully: "Claimed successfully!",
  depositDouble: "Deposit now and get double your deposit up to {{maximumFirstDeposit}}",

  AffiliateAlreadyExists: "A user is already registered!",
  EmailNotAvailableErrorType: "Email address not available!",

  selectAffiliateType: "Select the affiliate type that best represents you",

  affiliate: "Affiliate",
  streamer: "Streamer",
  agency: "Agency",
  other: "Other",

  affiliateRegistrationSentSuccessfully: "Registration sent for approval, we will contact you shortly.",
  sentSuccessfully: "Sent successfully!",
  sentRequest: "Send request",
  reviewAllInformation: "Please review all the information you entered in the previous steps and, if everything is correct, submit your request",
  youWillReceiveReturn: "You will receive a response within 72 hours",

  registrationData: "Registration Data",
  pleaseEnterRealInformationForApproval: "Please enter real information for approval.",

  tellUsWhatMotivatesYouToBecomeAnOnlybetsAffiliate: "Tell us what motivates you to become an Onlybets affiliate.",
  wereCuriousToKnowWhatMakesYouWantToJoinUs: "We are curious to know what makes you want to join us!",

  iHaveReadAndAcceptThePrivacyPolicy: "I have read and accept the Privacy Policy",

  iWouldLikeToBeAnAffiliate: "I would like to be an Affiliate",
  iWouldLikeToBeAnStreamer: "I would like to be a Streamer",
  iWouldLikeToBeAnAgency: "I would like to be an Agency",
  iWouldLikeToBeAnOther: "I would like to be another type of affiliate",

  affiliateTypeAffiliateDescription: "Bring in new users, motivate them, use my social networks and marketing strategies to attract players and receive commissions for it.",
  affiliateTypeStreamerDescription: "In addition to bringing in new users, I would like to use my charisma and knowledge to talk to players and motivate them through live broadcasts on the platform",
  affiliateTypeAgencyDescription: "I intend to bring in new affiliates and/or streamers to the platform, ensuring exponential growth, but I do not intend to broadcasts or motivate players directly.",
  affiliateTypeOtherDescription: "Bring new users, motivate them, use my social media and marketing strategies to attract players and get commission for it.",

  close: "Close",

  passwordValidationMessage: "The password must include at least 8 characters with letters and/or numbers and must not include spaces.",

  yourProgress: "Your progress",
  completed: "Completed",

  weNoticedThatYouAreNotYetConnectedToOurPlatform: "We noticed that you are not yet connected to our platform.",
  toAccessChooseOneOfTheOptionsBelow: "To access, choose one of the options below:",

  iWantToRegister: "I want to register",
  iAmAuser: "I am already a user",

  welcomeTo: "Welcome to",
  needHelp: "Need help?",
  clickHere: "Click here",

  yourFirstTimeItsSafeAndEasyToUse: "Your first time?",
  makeYourFirstDepositAndPlayTogetherWithYourFavoriteInfluencers: "Register and play now with one of our personalities.",
  makeAMinimumDepositToPlayWithTheInfluencer: "Make a deposit to play with your Influencer",
  makeADeposit: "Make a deposit",

  girsFromAllOverBrazilWantToKeepYouCompany: "Personalities from all over Brazil want to keep you company",
  dontHaveAnyDoubtsWeAlwaysHaveSomeoneAvailableToHelpYou: "Don't have any doubts, we always have someone available to help you.",

  wouldYouLikeToPlayWhileWatchingOneOfOurGirlsLive: "Would you like to play while watching one of our personalities live?",

  bonusAvailable: "Bonuses available",
  termsAndCondiction: "Terms and conditions",
  use: "Use",
  select: "Select",
  selected: "Selected",
  congratulationsYouHaveReceivedTheBonus: 'Congratulations, you have received the bonus "{{bonusName}}", click the button below for more details',
  accessMyBonuses: "Access my bonuses",

  bonusActive: "Bonus active",
  bonusAmount: "Bonus amount",
  betValueForCompletion: "Bet amount for completion",
  wageringRequirements: "Wagering requirements",

  days: "Days",
  hours: "Hours",
  minutes: "Minutes",
  seconds: "Seconds",

  timeRemainingForWagering: "Time remaining for wagering",
  congratulationsYouHaveClaimedYourBonus: "Congratulations, you claim your bonus worth {{redemptionBonus}} for real money!",

  activateNow: "Activate now",
  bonusActivatedSuccessfully: "Bonus activated successfully!",
  BonusActiveErrorType: "You already have an active bonus",
  BonusMinAmountErrorType: "Minimum deposit amount for bonus not reached",
  BonusMaxAmountErrorType: "Maximum deposit amount for bonus exceeded",

  active: "Active",
  activated: "Activated",

  dontForgetToActivateYourBonus: "Don't forget to activate your bonus",
  ifYouAlreadyHaveARegistration: "If you already have a registration",

  DepositTransactionPendindErrorType: "Deposit transaction in pending status!",

  promotionPageCardBonusTitle: "Regulated platform in Brazil!",
  promotionPageCardBonusSubtitle: "We are 100% in compliance with Brazilian legislation!",

  regulationText1: "Onlybets.tv is regulated in Brazil!",
  regulationText2: "What does this mean for you?",
  regulationText3: "✅ Security and transparency: We operate within all legal requirements established by the Brazilian Government, ensuring that your bets are placed in a safe and reliable environment.",
  regulationText4: "✅ Peace of mind: As of October 1, 2024, platforms that do not comply with the rules of Ordinance MF/SPA No. 1475 of September 17, 2024 will no longer be able to operate. However, at Onlybets, you can bet with the confidence that we are in the process of regulation.",
  regulationText5: "Bet safely and play with those who really understand nice to meet you!🔥",
  regulationText6: "Thank you for choosing Onlybets, where your well-being is our priority! 🎰✨",
  regulationText7: "+18 | Play responsibly 🔞",

  watchLive: "Watch live",
  congratulations: "Congratulations!",
  youHaveReleaseYyourBonus: "You have reached your rollover target and released your bonus!",

  playSafeAndHaveFun: "Play safe and have fun",
  weAreInComplianceWithBettingRegulationsClickAndFindOutMore: "We are 100% in compliance with betting regulations, click and find out more.",
  findOutMore: "Find out more",

  clickToClaiming: "Click to claim",
  gotToBonusCategory: "Go to bonus games",
  goBackCasino: "Back to casino",
  accessSubCategoryBonusDialog: "The game you selected is not available for the current bonus. Please go to the bonus eligible games section and continue your fun. Good luck!",

  depositWithBonusActive: "Please note! By continuing with this deposit, the currently active bonus promotion will be cancelled. However, you will be able to continue to access and play all games as normal. Please ensure this is your choice before proceeding.",

  theWorldsFirstPlatformWhereYouPlayAlongsideCelebrities: "The world's first platform where you play alongside celebrities",
  achieveVictoriesAlongsideYourFavoritePersonality: "Achieve victories alongside your favorite personality",

  noLiveStreamsAtTheMoment: "No live streams at the moment",
  noStreamersPlayingAtTheMoment: "No streamers playing at the moment",

  personalities: "Personalities",

  cookiesConsentTitle: "Cookie Consent",
  cookiesConsentDescription: 'We use cookies to improve your browsing experience, serve personalized ads or content, and analyze traffic. By clicking "Accept All", you consent to the use of cookies.',
  cookiesPolicy: "Cookie Policy",

  acceptAll: "Accept All",
  reject: "Reject",
  preferences: "Preferences",

  cookiesPreferencesTitle: "Basic information about cookies",
  cookiesPreferencesDescription: "When you visit any website, it may retrieve or save information on your browser, mostly through the use of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow us to use certain cookies. Click on the each category headings button as shown in our Cookie Policy to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the functionality we are able to offer.",
  consentPreferencesManager: "Manage cookie preferences consent",

  requiredCookies: "Strictly necessary cookies",
  functionalitiesCookies: "Functional cookies",
  performanceCookies: "Performance cookies",
  analyticsCookies: "Analytics cookies",

  requiredCookiesDescription: "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually set in response to actions made by you to receive services, such as adjusting your privacy preferences, logging in to the website or filling in forms. You can set your browser to block or alert you to the presence of these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.",
  functionalitiesCookiesDescription: "These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some of our services will not function properly.",
  performanceCookiesDescription: "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how many people visit the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site and therefore will not be able to track when you have visited.",
  analyticsCookiesDescription: "These cookies may be placed across the site by our advertising partners. These cookies may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.",
  saveMyPreference: "Save my preferences",
  cookiesPreferences: "Cookie preferences",

  otpCode: "Verification Code",
  otpCodeRequired: "Verification Code is Required",
  otpCodeSendedForEmail: "Verification Code sent to your email",
  otpCodeSendedForEmailInfo: "A verification code (One Time Password) has been sent to your email. Please check your inbox and enter the code to continue. If you cannot find the email, please also check your spam or junk folder.",

  OTPCodeNotSended: "An error occurred while sending the OTP verification code to your email",
  OTPCodeIsNotValid: "OTP verification code is not valid",
  OTPCodeRequired: "OTP verification code is required!",
  OTPCodeExpiredOrNotExists: "OTP verification code expired!",
  GenerateOTPErrorType: "Error generating OTP code",
  sendNewOtpCode: "Send new OTP code",

  OTPCodeRequestTitle: "Enable OTP code request when logging in?",
  OTPCodeDescrition: "An OTP (One Time Password) code is a temporary password generated to ensure the security of a transaction or authentication. It is used to verify the user's identity and is usually sent via email, SMS or authenticator app. The code has a limited validity and can only be used once, providing an extra layer of protection against unauthorized access.",

  disable: "Disable",
  otpRequestValidationEmailSended: "We have sent you an email to confirm that you have enabled the OTP code request when logging in. Please check your inbox and follow the instructions to complete the activation. If you cannot find the email, please also check your spam or junk folder.",

  maintenanceTitle: "We are currently performing a system migration.",
  maintenanceDescription: "Our services are temporarily unavailable. We are working to get back online soon and we appreciate your understanding!",
  UserOnTemporarySuspension: "Your account is temporarily suspended",

  userLimits: "User Limits",
  toolsTostayInControl: "Tools to stay in control",

  saveChanges: "Save Changes",
  theDepositLimitMayBeChangedAt: 'Deposit limit may be changed again on: {{date}}',
  NoValueChangesMadeError: "No value changes made",
  DepositLimitChangesByDaysExceededError: "Deposit limit changes by day exceeded!",

  CasinoBetLimitChangesByDaysExceededError: "Casino loss limit changes by day exceeded!",
  SportsBetLimitChangesByDaysExceededError: "Sports bet loss limit changes by day exceeded!",
  CasinoLossLimitChangesByDaysExceededError: "Casino loss limit changes by day exceeded!",
  SportsLossLimitChangesByDaysExceededError: "Sports bet loss limit changes by day exceeded!",
  CasinoBetLimitQuantityChangesByDaysExceededError: "Casino bet quantity limit changes by day exceeded!",
  SportsBetLimitQuantityChangesByDaysExceededError: "Sports bet quantity limit changes by day exceeded!",

  DailyDepositLimitReachedTypeError: "Daily deposit limit reached",
  WeeklyDepositLimitReachedTypeError: "Weekly deposit limit reached",
  MonthlyDepositLimitReachedTypeError: "Monthly deposit limit reached",

  limitAmountIsRequired: "Limit amount is required",
  limitCountIsRequired: "Limit amount is required",
  limitPeriodIsRequired: "Time period is required",
  timeLimitIsRequired: "Time limit is required",

  depositLimits: "Deposit limits",
  depositLimitsDescription: "A deposit limit allows you to set a specific amount of money that you can deposit during a certain period of time.",
  limitValue: "Limit value",
  quantityValue: "Limit amount",
  limitPeriod: "Time period",
  timelimitInHours: "Time limit in hours",

  betLimits: "Betting Limits",
  betLimitsDescription: "Bet Limits allow you to set a specific amount of money that you can gamble with during a given period of time.",

  lossLimits: "Loss Limits",
  lossLimitsDescription: "Loss Limits allow you to set a specific amount of money that you can lose during a given period of time.",

  theCasinoBetLimitMayBeChangedAt: 'Casino Bet Limit May Be Updated Again From {{date}}',
  theSportsBetLimitMayBeChangedAt: 'Sports Bet Limit May Be Updated Again From {{date}}',
  theCasinoLossLimitMayBeChangedAt: 'Casino Loss Limit May Be Updated Again From {{date}}',
  theSportsLossLimitMayBeChangedAt: 'Sports Loss Limit May Be Updated Again From {{date}}',
  theCasinoBetLimitQuantityMayBeChangedAt: 'Casino bet limit may be updated again from {{date}}',
  theSportsBetLimitQuantityMayBeChangedAt: 'Sports bet limit may be updated again from {{date}}',
  theSessionTimeLimitMayBeChangedAt: 'Session time limit may be updated again from {{date}}',

  breakPeriod: "Breakout period",
  breakPeriodDescription: "Breakout period is activated once you set a period and save changes, making you unable to place bets or play casino games during the selected period.",
  breakPeriodIsActiveUntil: 'Breakout period is active until {{date}}',

  betLimitsQuantity: "Betout limits",
  betLimitsDescriptionQuantity: "The bet limit allows you to set a specific amount of bets you can place.",

  UserWithActiveBreakPeriodError: "Break period activated!",
  userWithActiveBreakPeriod: 'You have an active break period until {{date}}',

  selectBreakPeriod: "Select break period",

  sessionTimeLimit: "Session Time Limit",
  sessionTimeLimitDescription: "The session time limit allows you to set a specific amount of time in hours that you can use.",

  UserSessionTimeLimitDailyReached: "Daily session time limit reached!",
  UserSessionTimeLimitWeeklyReached: "Weekly session time limit reached!",
  UserSessionTimeLimitMonthlyReached: "Monthly session time limit reached!",

  DailyCasinoBetQuantityLimitReached: "You have reached your daily casino bet amount limit!",
  WeeklyCasinoBetQuantityLimitReached: "You have reached your weekly casino bet amount limit!",
  MonthlyCasinoBetQuantityLimitReached: "You have reached your monthly casino bet amount limit!",

  DailyCasinoBetAmountLimitReached: "You have reached your daily casino bet amount limit!",
  WeeklyCasinoBetAmountLimitReached: "You have reached your weekly casino bet limit!",
  MonthlyCasinoBetAmountLimitReached: "You have reached your monthly casino bet limit!",
  DailyCasinoLossAmountLimitReached: "You have reached your daily casino loss limit!",
  WeeklyCasinoLossAmountLimitReached: "You have reached your weekly casino loss limit!",
  MonthlyCasinoLossAmountLimitReached: "You have reached your monthly casino loss limit!",
  sessionTimeDailyPeriodMax: "Maximum amount for daily period is 24 hours",
  sessionTimeWeeklyPeriodMax: "Maximum amount for weekly period is 168 hours",
  sessionTimeMonthlyPeriodMax: "Maximum amount for monthly period is 720 hours",
  youHaveATotalBet: "You have made a total of {{count}} bets, your limit is {{quantityLimit}}!",
  youBetATotalOf: "You have bet a total of {{amount}}, your limit is {{amountLimit}}!",
  youLossATotalOf: "You have lost a total of {{amount}} in casino bets, your loss limit is {{amountLimit}}!",

  TheBetAmountIsGreaterThanYourConfiguredLimit: "The bet amount was greater than your configured limit, you have bet a total of {{betAmount}} but your limit is {{betLimit}}.",

  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",

  details: "Detalles",
  generalInformation: "Información personal",
  passwordUpdatedSuccessfully: "¡Contraseña actualizada correctamente!",
  security: "Seguridad",
  availableBonusBalance: "Saldo de bonificación disponible",
  viewDocument: "Ver documento",

};
