import React, { memo, useMemo } from 'react'
import { Grid } from '@mui/material'
import { NavLink } from 'react-router-dom' // Import NavLink from react-router-dom
import { v4 as uuidv4 } from 'uuid'

const HeaderLinks = (props) => {
  const { className, renderLinksData } = props
  const showLinksData = useMemo(() => {
    const visibleLinks = renderLinksData?.filter(links => links?.show === true)
    return visibleLinks
  }, [renderLinksData])
  return (
    <Grid className={className}>
      {showLinksData?.map(linkData => (
        linkData.hide ? null :
          linkData?.onClick ? <a
            onClick={linkData?.onClick}
            key={uuidv4()}
          >
            {linkData?.name}
          </a> :
            <NavLink
              to={linkData?.srcUrl}
              key={uuidv4()}
            >
              {linkData?.name}
            </NavLink>
      ))}
    </Grid>
  )
}

HeaderLinks.defaultProps = {
  className: "",
  renderLinksData: []
}

export default memo(HeaderLinks)
