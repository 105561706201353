import { METHOD_TYPES } from "constants/index";
import axiosInstanceService from "network/apis/index";

/**
 * @param path - URL the path of the script file to be injected
 * @param options - `{ local?: 'head' | 'body', id?: string }`
*/
export const injectScript = (path, options = {}) => {
  const config = { local: 'head', ...options };
  const headers = { Accept: 'text/html' };

  let element = document.createElement("script");
  element.type = "text/javascript";
  element.async = true;
  element.id = `inject-script-${config.id || path}`;
  element.setAttribute('data-injectable-script', path);

  if (document.querySelector(`script[id=${element.id}]`)) return;

  axiosInstanceService(METHOD_TYPES.get, path, {}, {
    server: null,
    headers,
    responseType: "text",
  }).then(res => {
    if (Boolean(res?.data)) {
      element.textContent = res.data;
      document[config.local].appendChild(element);
    }
  })

}