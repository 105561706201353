
import { makeStyles } from '@mui/styles'

export const useReferAFriendStyles = makeStyles((theme) => ({

  centeredTypography: {
    padding: theme.spacing(0.625),
    fontWeight: "bold"
  },
  divider: {
    borderWidth: '2px',
    borderColor: theme.colors.btnSecondary,
    width: '50%',
    margin: theme.spacing(1.5, 0)
  },
  refferFriendContent: {
    padding: theme.spacing(2, 4),
    textAlign: "center",
    "& .receive-case-balance": {
      fontSize: theme.spacing(1),
      // borderWidth: "0 0 2px 0",
      borderColor: theme.colors.themeCard,
      // borderStyle: "solid",
      paddingBottom: theme.spacing(1),
    },
    "& .how-it-works": {
      fontSize: theme.spacing(1),
      borderColor: theme.colors.themeCard,
    },
    // "& .copy-input-wrap": {
    //   "& .MuiOutlinedInput-root": {
    //     background: theme.colors.themeCard,
    //     borderRadius: theme.spacing(0.625),
    //     "& .MuiInputBase-input": {
    //       fontSize: `${theme.spacing(0.875)} !important`,

    //     },
    //   },

    //   "& .MuiButtonBase-root, & .MuiBox-root": {
    //     height: theme.spacing(2),
    //     width: theme.spacing(2),
    //     minHeight: theme.spacing(2),
    //     background: theme.colors.btnSecondary,
    //     borderRadius: "100%",
    //   },
    // },

    "& .MuiTypography-body1": {
      marginTop: theme.spacing(0.625),
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "capitalize",
      "&.enter-text": {
        color: theme.colors.red,
        fontWeight: theme.typography.fontWeightBold
      },
    }
  },
  divider: {
    borderWidth: '2px',
    borderColor: theme.colors.btnSecondary,
    width: '100%',
    margin: theme.spacing(1.5, 0)
  },
}))
