import React from 'react';
import { Button, Typography, Grid } from '@mui/material';
import { usePaginationStyles } from './PaginationPage.styles';
import { useTranslation } from 'react-i18next';
import { colors } from 'theme/colors/index';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const classes = usePaginationStyles();

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const { t } = useTranslation()
    return (
        <Grid container className={classes.root} p={1}>

            <Button
                variant='contained'
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={classes.btnSecondary}
            >{t('prev')}</Button>

            <Typography variant="body1" pl={1} pr={1} color={colors.white}>
                {t('page')} {currentPage} of {totalPages}
            </Typography>


            <Button
                variant='contained'
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={classes.btnSecondary}>{t('next')}</Button>
        </Grid>
    );
};

export default Pagination;
