import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchStreamingsListService } from 'network/services/streaming.services';


/**
 * Fetch list from current live streamings or this game
 * @typedef { {casinoGameId?: number} } Payload
 * @type { (params: {payload?: Payload}) => any }
 */
export const fetchStreamingsListThunk = createAsyncThunk('stream/streamings-list', async ({ payload, navigate }, thunkApi) => {
  try {
    const res = await fetchStreamingsListService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})


/**
 * Fetch list from current live streamings or this game
 * @typedef { {casinoGameId?: number} } Payload
 * @type { (params: {payload?: Payload}) => any }
 */
export const fetchStreamingsCasinoGameListThunk = createAsyncThunk('stream/streamings-casinoGame', async ({ payload, navigate }, thunkApi) => {
  try {
    const res = await fetchStreamingsListService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
