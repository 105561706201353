import React from 'react'
import {
  Grid,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { useStyles } from './style'

export const DialogBox = (props) => {
  const { open, handleClose, title, renderComponent } = props
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-description'
      maxWidth='lg'
      className={classes.searchGameModal}
    >
      <DialogTitle display='flex' className='modalHeading'>
        <Typography variant='h6' sx={{ flexGrow: '1', textAlign: 'center' }}>
          {title}
        </Typography>

        <Grid sx={{ cursor: 'pointer' }}>
          <CloseIcon onClick={handleClose} />
        </Grid>
      </DialogTitle>

      <DialogContent className='modalBody'>
        {renderComponent}
      </DialogContent>
    </Dialog>
  )
}
