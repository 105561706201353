
import React from 'react'
import { Container, Typography, Button } from '@mui/material'
import { theme } from 'theme/index'
import { useTranslation } from 'react-i18next'
import { colors } from 'theme/colors/index'

const ErrorFallbackRender = ({
  resetErrorBoundary
  // error
}) => {
  const {t} = useTranslation()
  return (
    <>
      <Container maxWidth='md' className='coming-soon-container'>
        <Typography variant='body1' align='center' style={{ color: colors.white, marginTop: '20px' }}>
          {t('siteUnderDevelopment')}
        </Typography>
        <Typography variant='body1' align='center' style={{ color: colors.white, marginTop: '20px' }}>
          <Button
            onClick={resetErrorBoundary}
            style={{
              marginTop: '20px',
              background: theme.colors.btnSecondary,
              color: theme.colors.footerBg,
              fontWeight: theme.typography.fontWeightSemiBold,
              fontSize: theme.spacing(0.875),
              '&:hover': {
                background: theme.colors.btnSecondaryHover,
                color: theme.colors.white
              }
            }}
          >
            {t('refreshPage')}
          </Button>
        </Typography>
      </Container>
    </>
  )
}

export default ErrorFallbackRender
