import { createSlice } from '@reduxjs/toolkit';
import { fetchSocialLinksThunk } from 'redux-thunk/thunk/footer/footer.thunk';

const initialState = {
    socialMedialLinksData: null
}

const footerSlice = createSlice({
    name: 'footerSlice',
    initialState: initialState,
    reducers: {
        setSocialMedialLinksData: (state, action) => {
            return {
                ...state,
                socialMedialLinksData: action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSocialLinksThunk.fulfilled, (state, action) => {
                return {
                    ...state,
                    socialMedialLinksData: action.payload
                }
            })
            .addCase(fetchSocialLinksThunk.pending, (state, action) => {
                return {
                    ...state
                }
            })
            .addCase(fetchSocialLinksThunk.rejected, (state, action) => {
                return {
                    ...state,
                    socialMedialLinksData: action.payload
                }
            })
    }
})

export const { setSocialMedialLinksData } = footerSlice.actions

export default footerSlice.reducer
