import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchLoyaltyRulesService } from 'network/services/loyalty.service'


export const fetchLoyaltyRulesThunk = createAsyncThunk('loyalty/rules', async ({ payload }, thunkApi) => {
  try {
    const res = await fetchLoyaltyRulesService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})


