import { createSlice } from '@reduxjs/toolkit'

import { fetchAllCmsData } from 'redux-thunk/thunk/cms/cms.thunk'

const defaultState = {
  cmsData: {}
}

const cmsSlice = createSlice({
  name: 'cmsSlice',
  initialState: defaultState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCmsData.fulfilled, (state, action) => {
        return {
          ...state,
          cmsData: action.payload
        }
      })
  }
})

export const { handleUserNameChange, handleLanguageChange, handleBalanceChange } = cmsSlice.actions

export default cmsSlice.reducer
