import { styled, Dialog } from '@mui/material'

export const StyledDialog = styled(Dialog)((theme) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '20px',
  "&.confirmation-modal": {
    "& .MuiTypography-root": {
      fontSize: "1.2rem",
      marginBottom: "1.5rem",
    },
    "& .MuiButton-root": {
      "&:hover": {
        background: "rgb(0, 49, 68)",
      }
    }
  },

}))
