import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  getAllCms
} from 'network/services/cms.services'

/**
 * Fetch CMS Data Thunk
 */
export const fetchAllCmsData = createAsyncThunk('fetch/cms', async (thunkApi) => {
  try {
    const res = await getAllCms()
    const cmsPages = res.cmsPages.rows
    const cms = {}
    cmsPages.forEach(element => {
      cms[element?.slug] = element
    })
    return cms
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
