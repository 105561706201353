import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeHeaderLinkTabId: null,
  showHeader:true
}

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState: initialState,
  reducers: {
    setActiveHeaderLinkTabId: (state, actions) => {
      return {
        ...state,
        activeHeaderLinkTabId: actions?.payload
      }
    },
    setShowHeader: (state, actions) => {
      return {
        ...state,
        showHeader: actions?.payload
      }
    }
  }
})

export const { setActiveHeaderLinkTabId,setShowHeader } = commonSlice.actions
export default commonSlice.reducer
