import { METHOD_TYPES, LOADER_HANDLER_TYPES } from 'constants/index'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'


export const fetchTopWinnersDetailService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/report/get-top-winners'
  , {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}
export const fetchCurrentWinnersDetailService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/report/get-top-winners', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}