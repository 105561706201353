import { createSlice } from '@reduxjs/toolkit'
import { fetchLoyaltyRulesThunk } from 'redux-thunk/thunk/loyalty/loyalty.thunk'

const initialState = {
    levelData :[],
    levelDataLoader:false
}

const loyaltySlice = createSlice({
  name: 'loyaltySlice',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoyaltyRulesThunk.fulfilled, (state, action) => {
        return {
            ...state,
            levelData: action?.payload?.rules,
            levelDataLoader:false
        }
      })
      .addCase(fetchLoyaltyRulesThunk.pending, (state, action) => {
        return {
            ...state,
            levelDataLoader:true
        }
      })
      .addCase(fetchLoyaltyRulesThunk.rejected, (state, action) => {
        return {
            ...state,
            levelData:action.payload,
            levelDataLoader:false
        }
      })
  }
})

// export const {  } = loyaltySlice.actions

export default loyaltySlice.reducer
