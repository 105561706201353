import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({

  wrapper: {
    background: "#0A0C10",
    minHeight: "100vh",
    minHeight: "100dvh",
    color: theme.colors.white,
    padding: theme.spacing(2, 0),
    position: "relative",
    display: "flex",
    alignItems: "center",
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      alignItems: "start",
      padding: theme.spacing(3, 0, 2),
    },
    "& .MuiContainer-root": {
      zIndex: 3,
    },
    "& .onlybets-logo": {
      marginBottom: theme.spacing(2.5),
      width: theme.spacing(15),
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(10),
      },
    },
    "& h1": {
      fontSize: theme.spacing(5),
      lineHeight: theme.spacing(5),
      margin: 0,
      marginBottom: theme.spacing(2.5),
      background: 'rgb(255,255,255)',
      background: 'linear-gradient(180deg, #FFFFFF 0%, #858585 100%)',
      "-webkit-background-clip": 'text',
      "-webkit-text-fill-color": 'transparent',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(4),
        lineHeight: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        lineHeight: theme.spacing(3),
      },
    },
    "& p": {
      fontSize: theme.spacing(1.2),
      color: theme.colors.placeHolderColor,
    },

    "& .background-blur-color": {
      position: "absolute",
      width: '100%',
      height: '100%',
      background: "conic-gradient(from 209.41deg at 35.04% 43.72%, rgb(0 0 0 / 94%) -65.86deg, #000000 43.56deg, #000000 97.31deg, rgb(0 0 0 / 57%) 250.8deg, rgb(8 70 108 / 52%) 294.14deg, #006D96 403.56deg)",
      filter: "blur(6rem)",
      top: 0, left: 0,
    },
    "& .OnlybetsWebsiteDesktop, & .OnlybetsWebsiteMobile": {
      position: "absolute",
      zIndex: 2,
      borderRadius: theme.spacing(0.5),
      transform: 'rotate(347deg)',
    },
    "& .OnlybetsWebsiteDesktop": {
      right: theme.spacing(-25),
      bottom: theme.spacing(-10),
      width: "initial",
      boxShadow: "0px 0px 10rem 2rem #000",
      [theme.breakpoints.down('xl')]: {
        width: "75%",
      },
      [theme.breakpoints.down('md')]: {
        right: "-35%",
        bottom: theme.spacing(-10),
        width: "100%",
      },
      [theme.breakpoints.down('sm')]: {
        bottom: theme.spacing(-2),
      },
    },
    "& .OnlybetsWebsiteMobile": {
      right: theme.spacing(45),
      bottom: theme.spacing(-20),
      width: "initial",
      [theme.breakpoints.down('xl')]: {
        width: "20%",
        left: theme.spacing(32),
      },
      [theme.breakpoints.down('md')]: {
        left: "initial",
        right: "55%",
        bottom: theme.spacing(-20),
        width: "30%",
      },
      [theme.breakpoints.down('sm')]: {
        bottom: theme.spacing(-8),
      },
    },
    "& .circular-lines": {
      zIndex: 1,
      "& .line-1, & .line-2": {
        position: "absolute",
        borderRadius: "50%",
        border: `solid 2px #263940`,
        aspectRatio: '1 / 1',
      },
      "& .line-1": {
        top: theme.spacing(-1),
        width: theme.spacing(100),
        left: "45%",
      },
      "& .line-2": {
        top: theme.spacing(-1),
        width: theme.spacing(100),
        left: "45%",
        transform: "scale(0.8)",
        "&::before, &::after": {
          content: "''",
          position: "absolute",
          width: theme.spacing(1),
          height: theme.spacing(1),
          borderRadius: "50%",
          background: "#263940",
        },
        "&::before": {
          top: theme.spacing(35),
          left: theme.spacing(1.6),
        },
        "&::after": {
          top: theme.spacing(0.4),
          right: theme.spacing(40),
        },
      }
    },
  },

}))
