import { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import AppLoader from 'components/ui-kit/AppLoader/index'
import { LOADER_TYPE } from 'constants/index'
import { colors } from 'theme/colors/index'
import CustomRoutes from './routes'

const Routes = ({ pageLoader, RouterContextComponent }) => {
  return (
    <Router>
      <Suspense fallback={
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%'
        }}
        >
          {!pageLoader && <AppLoader variant={LOADER_TYPE.SCALE} color={colors.white} />}
        </div>
      }
      >
        <CustomRoutes />
        {RouterContextComponent}
      </Suspense>
    </Router>
  )
}

export default Routes
