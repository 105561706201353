import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import useStyles from "./style";
import { Link } from "react-router-dom";
import CookieIcon from '@mui/icons-material/Cookie';
import Button from "components/Button/index";
import { DialogComponent } from "../Dialog/index";
import { ROUTE_PATHS } from "constants/index";
import CloseIcon from '@mui/icons-material/Close';

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Switch from "@mui/material/Switch";
import useLocalStorage from "hooks/useLocalStorage";
import { getCookiesConsent, setCookiesConsent } from "helpers/cookie.helpers";
import { useDispatch, useSelector } from "react-redux";
import { openCookiesConsent } from "redux-thunk/redux/site/site.slice";
import classNames from "../../../../node_modules/classnames/index";
import { motion, AnimatePresence } from "framer-motion";
import useDebounce from "hooks/useDebounce";

const cookieConsentStorageName = 'cookiesPreferences';

const cookiesPreferencesInitialValues = [
  { id: "required", title: "requiredCookies", description: "requiredCookiesDescription", checked: true, disabled: true },
  { id: "functionalities", title: "functionalitiesCookies", description: "functionalitiesCookiesDescription", checked: false },
  { id: "performances", title: "performanceCookies", description: "performanceCookiesDescription", checked: false },
  { id: "analytics", title: "analyticsCookies", description: "analyticsCookiesDescription", checked: false },
];

const AccordionItem = ({ id, title, disabled, checked, description, onChange }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Accordion expanded={open} >
      <AccordionSummary
        expandIcon={<Switch id={id} defaultChecked={checked} disabled={disabled} onChange={onChange} />}
        aria-controls="panel1a-content"
        id={id}
      >
        <Typography onClick={() => setOpen(!open)}>
          {t(title)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {t(description)}
      </AccordionDetails>
    </Accordion>
  );
}

export const CookiesConsent = ({ onCookiesConsent = () => null }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookiesPreferences, setCookiesPreferences] = useState(cookiesPreferencesInitialValues);
  const { openCookiesConsentBanner } = useSelector((state) => state.site);

  const { setStorage, getStorage } = useLocalStorage((storage, exits) => {
    if (exits(cookieConsentStorageName)) {
      setCookiesPreferences((state) => {
        const s = state.map((e) => {
          const newState = storage[cookieConsentStorageName].find(({ id }) => (e.id == id)) || {}
          return { ...e, ...newState };
        })
        return s;
      })
    }
  });

  const acceptAll = () => {
    setCookiesPreferences(state => {
      const s = state.map(e => (!e.disabled ? { ...e, checked: true } : e));
      save(s, "accept");
      return s
    });
  }

  const reject = () => {
    setCookiesPreferences(state => {
      const s = state.map(e => (!e.disabled ? { ...e, checked: false } : e));
      save(s, "reject");
      return s
    });
  }

  const onChange = ({ target: { checked, id } }) => {
    setCookiesPreferences(state => state.map(e => (e.id == id) ? { ...e, checked } : e));
  }

  const save = (cks = [], actionType) => {
    const cookiesPreferences = cks.filter(e => !e.disabled).map(({ id, checked }) => ({ id, checked }));
    const hasCookiesConsent = getCookiesConsent();
    setStorage(cookieConsentStorageName, cookiesPreferences);
    setCookiesConsent(true);
    closePreferences();
    if (actionType == "accept" || hasCookiesConsent) {
      window.location.reload();
    }
  }

  const openPreferences = () => {
    dispatch(openCookiesConsent(true));
  };

  const closePreferences = () => {
    dispatch(openCookiesConsent(null));
  };

  const handleOnCookiesConsent = useDebounce(() => onCookiesConsent(getStorage(cookieConsentStorageName) || []), 500);

  useEffect(handleOnCookiesConsent, []);

  return (<>
    <AnimatePresence exitBeforeEnter>
      {(!getCookiesConsent()) ?
        <motion.div
          className={classNames(classes.floatBoxWrapper)}
          initial={{ x: "-50%", y: "150%", scale: 0.8 }}
          animate={{ opacity: 1, x: "-50%", y: 0, scale: 1 }}
          exit={{ opacity: 0, x: "-50%", y: "150%", scale: 0.8 }}
          transition={{ delay: (!getCookiesConsent() || openCookiesConsentBanner) ? 5 : 0 }}
        >
          <Grid>
            <Typography variant="h6"><CookieIcon /> {t("cookiesConsentTitle")}</Typography>
            <Typography>{t("cookiesConsentDescription")} <Link to={`${ROUTE_PATHS.support}?${new URLSearchParams({ id: "politica-cookies" })}`} >{t("cookiesPolicy")}</Link></Typography>
          </Grid>
          <Grid className={classes.footer}>
            <Button size="sm" onClick={openPreferences} variant="primary">{t("preferences")}</Button>
            <Button size="sm" onClick={reject} variant="primary">{t("reject")}</Button>
            <Button size="sm" onClick={acceptAll}>{t("acceptAll")}</Button>
          </Grid>
        </motion.div>
        : null}
    </AnimatePresence>
    <DialogComponent dialogOpen={openCookiesConsentBanner} maxWidth className={classes.dialogComponent}>
      <Grid className={classes.dialog}>

        <Button variant='primary' icon className={`${classes.btnClose}`} onClick={closePreferences}>
          <CloseIcon />
        </Button>

        <Typography variant="h6">{t("cookiesPreferencesTitle")}</Typography>
        <Typography>{t("cookiesPreferencesDescription")}</Typography>
        <Typography>
          <Link to={`${ROUTE_PATHS.support}?${new URLSearchParams({ id: "politica-cookies" })}`} onClick={closePreferences} >{t("moreInformation")}</Link>
        </Typography>

        <Grid className={classes.dialogFooter}>
          <Button size="sm" onClick={acceptAll}>{t("acceptAll")}</Button>
        </Grid>
        <Typography variant="h6">{t("consentPreferencesManager")}</Typography>
        <Grid className={classes.accordionWrapper}>
          {cookiesPreferences?.map((props) => (
            <AccordionItem {...props} {...{ onChange }} />
          ))}
        </Grid>
        <Grid className={classes.footer}>
          <Button size="sm" onClick={() => save(cookiesPreferences, 'accept')}>{t("saveMyPreference")}</Button>
        </Grid>
      </Grid>
    </DialogComponent>
  </>);
};

export default CookiesConsent;