import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import { truncate } from 'lodash';
import axiosInstanceService from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';

/** Payment provider list
 * @typedef { {providers: any[], pix: any[], payment_types: string[]} } PaymentProvidersResponse
 * @type { (payload?: { withdrawal?: boolean, deposit?: boolean }) => Promise<PaymentProvidersResponse> }
 */
export const fetchPaymentProvidersService = (payload = {}) => {
  const instance = axiosInstanceService(METHOD_TYPES.get, `/payments/providers?${new URLSearchParams(payload).toString()}`, {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
  return instance
}

/** Deposit with pix
 * @typedef { {} } DepositPixResponse
 * @typedef { {amount: number, paymentProviderId: number, currency?: string} } DepositPixPayload
 * @type { (payload?: DepositPixPayload) => Promise<DepositPixResponse> }
 */
export const depositPixService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, `/payments/deposit/pix`, payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

/** Withdraw with pix
 * @typedef { {} } WithdrawPixResponse
 * @typedef { {value: number, paymentProviderId: number, pix_key_type: string, pix_key: string} } WithdrawPixPayload
 * @type { (payload: WithdrawPixPayload) => Promise<WithdrawPixResponse> }
 */
export const withdrawPixService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, `/payments/withdraw/pix`, payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}


/** Validate Bonus Code
 * @typedef { {} } ValidateBonusCodeResponse
 * @typedef { { depositBonusCode: string } } ValidateBonusCodePayload
 * @type { (payload: ValidateBonusCodePayload) => Promise<ValidateBonusCodeResponse> }
 */
export const validateBonusCodeService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, `/payments/validateBonusCode`, payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    showApiErrorMessage: false
  })
}