import { dateFormat } from "./common";

export const convertToReadableDateAndTime = (dateString, format) => {
  const date = new Date(dateString);
  const timestamp = date.getTime();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  switch (format) {
    case dateFormat?.date_DD_MM_YYYY:
      return `${day}-${month}-${year}`;

    case dateFormat?.date_MM_DD_YYYY:
      return `${month}-${day}-${year}`;

    case dateFormat?.time_HH_MM_SS:
      return `${hours}:${minutes}:${seconds}`;

    case dateFormat?.date_DD_MM_YYYY_Time_H_M_S:
      return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;

    case dateFormat?.time_stamp:
      return timestamp;

    default:
      return "Invalid format";
  }
};


export const  convertTimeToTimestamp =(timeString)=> {
  var [hours, minutes] = timeString.split(':').map(Number);
  var date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.getTime();
}

