import { Navigate } from 'react-router-dom'

import MainLayout from 'components/layouts/MainLayout/index'
import { ROUTE_PATHS } from 'constants/index'
import { DialogComponent } from 'components/ui-kit/Dialog/index'

const PrivateRoute = ({ Component, isAuthenticated, hasNavBar }) => {
  return isAuthenticated
    ? (
      <>
        <MainLayout header={hasNavBar}>
          {Component}
        </MainLayout>
      </>
      )
    : <Navigate to={ROUTE_PATHS.home} />
}

export default PrivateRoute
