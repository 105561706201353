import React from 'react'
import {
    Grid,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDidYouKnowStyles } from './Searchgame.styles'
import { colors } from 'theme/colors/index'

const DidYouKnow = (props) => {

    const { image, title, subtitle } = props;

    const { t } = useTranslation()
    const classes = useDidYouKnowStyles()
    return (
        <Grid sx={{ padding: '40px', textAlign: 'center' }}>
            {image && <img
                src={image}
                alt='modalImage'
                className={classes.noDataImage}
            />}
            {title &&<Typography
                variant='h6'
                sx={{ margin: '10px', color: colors.white }}
            >
                { title }
            </Typography>}

            {subtitle &&<Typography sx={{ color: colors.white }}>
                { subtitle }
            </Typography>}
        </Grid>
    )
}

export default DidYouKnow
