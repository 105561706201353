import { useEffect } from 'react'
import config from 'config/app.config';
import { getAuthToken } from 'helpers/cookie.helpers';
import createSocketConnection from '../socket-connection/index';

import { USER_CONNECTION_NAMESPACE } from '../namespaces/index';

let useUserConnectionSocketInstance = null
const useUserConnectionNameSpace = () => {
  const initializeSocketConnection = () => {
    const authToken = getAuthToken();
    if (authToken && !useUserConnectionSocketInstance?.connected) {
      console.log("user socket connection");
      useUserConnectionSocketInstance = createSocketConnection(`${config.SOCKET_URL.replace(/\/+$/, '')}${USER_CONNECTION_NAMESPACE}`, authToken);
    }
  }

  useEffect(() => {
    initializeSocketConnection();
    return () => {
      useUserConnectionSocketInstance = null;
    }
  }, [])

  return { startUserConnection: initializeSocketConnection };
}

export default useUserConnectionNameSpace;
