import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSiteInformationSettingsThunk } from "redux-thunk/thunk/site/site.thunk";
import AppLoader from "components/ui-kit/AppLoader/index";
import { LOADER_TYPE } from "constants/index";
import { colors } from "theme/colors/index";
import { theme } from "theme/index";
import config from "config/app.config";
import MaintenancePage from "pages/MaintenancePage/index";

const GetSiteInformationSettings = ({ children }) => {
  const dispatch = useDispatch();
  const { settings, settingsLoading } = useSelector((state) => state.site);

  useEffect(() => {
    dispatch(getSiteInformationSettingsThunk({}));
  }, []);

  useEffect(() => {
    if (typeof window && settings.name) window.document.title = settings.name;
  }, [settings])

  return (<>
    {settingsLoading && (
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        width: "100%",
        minHeight: "100vh",
        minHeight: "100dvh",
        backgroundColor: theme.colors.primaryLightBlue,
        zIndex: 9999
      }}>
        <AppLoader variant={LOADER_TYPE.SCALE} color={colors.white} />
      </div>
    )}
    {(config.maintenance || settings.maintenance) ?
      <MaintenancePage />
      : children
    }
  </>)
}

export default GetSiteInformationSettings;