import React, { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const FooterLinks = (props) => {
  const { className, renderLinksData, title, isTargetBlank } = props;
  return (
    <Grid className={className}>
      <Typography variant='h4'>{title}</Typography>
      {renderLinksData?.map(linkData => (
        isTargetBlank ?
          <a
            href={linkData?.link}
            target='_blank'
            rel='noreferrer'
            className='nav-link'
            key={uuidv4()}>
            {linkData?.name}
          </a> :
          <NavLink
            to={linkData?.srcUrl}
            key={uuidv4()}
          >
            {linkData?.name}
          </NavLink>
      ))}
    </Grid>
  );
};

FooterLinks.defaultProps = {
  className: '',
  renderLinksData: [],
  title: '',
  isTargetBlank: false,
};
export default memo(FooterLinks);
