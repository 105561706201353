import { createSlice } from '@reduxjs/toolkit'

import { LANGUAGES } from 'constants/index'
import { getLocalLanguage, setLocalLanguage } from 'helpers/localstorage.helpers'
import {
  fetchInfluencerListServiceThunk,
  fetchInfluencerListSocketServiceThunk,
  fetchPlayingNowListServiceThunk,
  fetchLivesListServiceThunk
} from 'redux-thunk/thunk/influencer/influencer.thunk';

const defaultInfluencersState = {
  language: getLocalLanguage() || LANGUAGES[0].languageCode,
  influencersList: {
    count: 0,
    rows: [],
  },
  playingNowList: [],
  playingNowListLoader: true,
  influencersListLoader: true,
  liveList: [],
  liveListLoader: true,
}

const influencersSlice = createSlice({
  name: 'influencersSlice',
  initialState: defaultInfluencersState,
  reducers: {
    handleLanguageChange: (state, action) => {
      setLocalLanguage(action.payload)
      return {
        ...state,
        language: action.payload
      }
    },
    setPlayingNowList: (state, action) => {
      return {
        ...state,
        playingNowList: action.payload,
        playingNowListLoader: false
      }
    },
    setLiveList: (state, action) => {
      return {
        ...state,
        liveList: action.payload,
        liveListLoader: false
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencerListSocketServiceThunk.fulfilled, (state, action) => {
        return {
          ...state,
          influencersList: action.payload.influencers,
          playingNowList: action.payload.playingNow,
        }
      })
      .addCase(fetchInfluencerListServiceThunk.fulfilled, (state, action) => {
        return {
          ...state,
          influencersList: action.payload.influencers,
          playingNowList: action.payload.playingNow,
          influencersListLoader: false
        }
      })
      .addCase(fetchInfluencerListServiceThunk.pending, (state, action) => {
        return {
          ...state,
          influencersListLoader: true
        }
      })
      .addCase(fetchPlayingNowListServiceThunk.fulfilled, (state, action) => {
        return {
          ...state,
          playingNowList: action.payload.playingNow,
          playingNowListLoader: false
        }
      })
      .addCase(fetchPlayingNowListServiceThunk.pending, (state, action) => {
        return {
          ...state,
          playingNowListLoader: true
        }
      })
      .addCase(fetchLivesListServiceThunk.fulfilled, (state, action) => {
        return {
          ...state,
          liveList: action.payload?.lives,
          liveListLoader: false
        }
      })
      .addCase(fetchLivesListServiceThunk.pending, (state, action) => {
        return {
          ...state,
          liveListLoader: true
        }
      })
  }
})

export const { handleLanguageChange, setPlayingNowList, setLiveList } = influencersSlice.actions

export default influencersSlice.reducer
