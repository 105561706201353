import React, { forwardRef, memo, useImperativeHandle, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DialogComponent } from 'components/ui-kit/Dialog/index'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/index'
import { useNotSigninStyles } from './style'
import { colors } from 'theme/colors/index'
import Button from 'components/Button/index'
import { BrandLogoIcon } from 'components/ui-kit/icons/iconComponents/BrandLogoIcon'
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux'
import { getAuthToken } from 'helpers/cookie.helpers'

const InSufficiantBalDialog = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const classes = useNotSigninStyles();
  const [dialogOpen, setDialogOpen] = useState();

  const { t } = useTranslation();
  const { userInformationData } = useSelector((state) => state.user);

  const { settings: { minimumAmountToAccessGames = 1, minimumAmountToAccessLives = 1 } } = useSelector((state) => state.site);

  const closeModal = () => {
    setDialogOpen(false);
  }

  const open = () => {
    setDialogOpen(true);
  }

  const handleMakeDeposit = () => {
    navigate(ROUTE_PATHS?.deposit)
    closeModal();
  }

  const handleClose = () => {
    closeModal();
  }

  const validate = (/** @type {"game" | "live"} */type, callback = () => null, error = () => null) => {
    const minimum = {
      game: minimumAmountToAccessGames,
      live: minimumAmountToAccessLives,
    }
    return new Promise((resolve) => {
      if (userInformationData?.userWallet?.totalAmount < minimum[type]) {
        setDialogOpen(true);
        resolve(true);
        error("User does not have sufficient balance");
      } else {
        callback();
        resolve(false);
      };
    })
  }

  useImperativeHandle(ref, () => ({ validate, open }));

  return (getAuthToken() ?
    <DialogComponent dialogOpen={dialogOpen}>
      <Grid className={classes.container} container direction='column' alignContent='center'>
        <Button variant='primary' icon className={`${classes.btnClose}`} onClick={closeModal}>
          <CloseIcon />
        </Button>
        <Grid className={classes.header}>
          {/* <Typography variant={"h5"}>{t("welcomeTo")}</Typography> */}
          <BrandLogoIcon />
        </Grid>
        <Grid item xs={6}>
          <Typography align='center'>
            {t('makeAMinimumDepositToPlayWithTheInfluencer')}
          </Typography>
        </Grid>
        <Grid item container justifyContent='center' pt={1.5} pb={1.5}>
          <Grid item xs={12} mb={1}>
            <Button onClick={handleMakeDeposit} className={classes.btn}>{t('makeADeposit')}</Button>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleClose} variant='tertiary' className={classes.btn}>{t('cancel')}</Button>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography align='center'>
            {t('needHelp')} <a style={{ cursor: "pointer" }} onClick={() => window.Tawk_API?.maximize()}>{t('clickHere')}</a>
          </Typography>
        </Grid>
      </Grid>
    </DialogComponent>
    : null)
})

export default memo(InSufficiantBalDialog)
