import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCurrentWinnersDetailThunk,
  fetchTopWinnersDetailThunk,
} from "redux-thunk/thunk/winners/winners.thunk";

const initialState = {
  topWinnersDetailsData: [],
  currentWinnersDetailsData: [],
  loader: {
    topWinnerLoader: false,
    currentWinnerLoader: false,
  },
};

const winnersSlice = createSlice({
  name: "winnersSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentWinnersDetailThunk.fulfilled, (state, action) => {
        return {
          ...state,
          currentWinnersDetailsData: action.payload,
          loader: {
            ...state.loader,
            currentWinnerLoader: false,
          },
        };
      })
      .addCase(fetchCurrentWinnersDetailThunk.pending, (state, action) => {
        return {
          ...state,
          loader: {
            ...state.loader,
            currentWinnerLoader: true,
          },
        };
      })
      .addCase(fetchCurrentWinnersDetailThunk.rejected, (state, action) => {
        return {
          ...state,
          currentWinnersDetailsData: action.payload,
          loader: {
            ...state.loader,
            currentWinnerLoader: false,
          },
        };
      })
      .addCase(fetchTopWinnersDetailThunk.fulfilled, (state, action) => {
        return {
          ...state,
          topWinnersDetailsData: action.payload,
          loader: {
            ...state.loader,
            topWinnerLoader: false,
          },
        };
      })
      .addCase(fetchTopWinnersDetailThunk.pending, (state, action) => {
        return {
          ...state,
          loader: {
            ...state.loader,
            topWinnerLoader: true,
          },
        };
      })
      .addCase(fetchTopWinnersDetailThunk.rejected, (state, action) => {
        return {
          ...state,
          topWinnersDetailsData: action.payload,
          loader: {
            ...state.loader,
            topWinnerLoader: false,
          },
        };
      });
  },
});

export default winnersSlice.reducer;
