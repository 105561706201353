import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackRender from "components/layouts/ErrorPage/index";
import AppToaster from "components/ui-kit/AppToaster/index";
import AppRoutes from "routes/index";
import "./assets/css/App.css";
import { useSelector } from "react-redux";

function App({ RouterContextComponent }) {
  const pageLoading = useSelector((state) => state.loader.pageLoader);
  return (
    <ErrorBoundary fallbackRender={ErrorFallbackRender}>
      <AppToaster />
      <AppRoutes pageLoader={pageLoading} {...{ RouterContextComponent }} />
    </ErrorBoundary>
  );
}

export default App;
