import config from "config/app.config"
import { ROUTE_PATHS } from "constants/index"

export const headerLinks = (t, /** @type { import("hooks/useSettings").SiteSettings } */ settings, hide = []) => {
  const headerLinkData = [
    {
      show: true,
      name: t('casino'),
      srcUrl: ROUTE_PATHS?.casino
    },
    {
      show: true,
      name: t('liveCasino'),
      srcUrl: ROUTE_PATHS?.liveCasino
    },
    {
      show: settings?.sports,
      name: t('sports'),
      srcUrl: ROUTE_PATHS?.sports,
    },
    {
      show: true,
      name: t('Lives'),
      srcUrl: ROUTE_PATHS?.lives
    },
    {
      show: true,
      name: t('playingNow'),
      srcUrl: ROUTE_PATHS?.playingNow
    },
    {
      show: true,
      name: t('promotions'),
      srcUrl: ROUTE_PATHS.promotions
    },
    {
      show: true,
      name: t('loyalty'),
      srcUrl: ROUTE_PATHS.loyalty,
      hide: hide.includes('loyalty')
    },
    {
      show: true,
      name: t('winners'),
      srcUrl: ROUTE_PATHS.winners
    },
    {
      show: true,
      name: t('liveSupport'),
      srcUrl: "#",
      onClick: () => window.Tawk_API?.maximize()
    }
  ]
  return headerLinkData

}

export const dateFormat = {
  date_DD_MM_YYYY_Time_H_M_S: "DD-MM-YYYY, HH:MM:SS",
  date_DD_MM_YYYY: "DD-MM-YYYY",
  date_MM_DD_YYYY: "MM-DD-YYYY",
  time_HH_MM_SS: "HH:MM:SS",
  time_stamp: "timestamp"
}
