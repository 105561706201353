import React from "react";
import { getAuthToken, removeAuthToken } from "helpers/cookie.helpers";
import { removeBetbyToken } from "helpers/localstorage.helpers";
import { useEffect } from "react";
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, setSportsbookToken } from "redux-thunk/redux/user/user.slice";
import useUserConnectionNameSpace from "socket-resources/hooks/useUserConnectionNameSpace";
import { fetchUserInformationThunk } from "redux-thunk/thunk/user/user.thunk";


const UserConnection = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.user);

  // USER SOCKET CONNECTION
  const { startUserConnection } = useUserConnectionNameSpace();

  // USER IDLE 
  const { start } = useIdleTimer({
    onIdle: () => {
      console.log("User idle: desconnecting...");

      dispatch(setLoggedIn({ isLoggedIn: false }));
      removeAuthToken();
      removeBetbyToken();
      dispatch(setSportsbookToken(null));
      window.location.reload();
    },
    startManually: true,
    timeout: 1800_000,
    throttle: 500
  });


  useEffect(() => {
    if (isLoggedIn) {
      if (getAuthToken()) {
        // GET USER INFORMATION
        dispatch(fetchUserInformationThunk())
      }
      startUserConnection();
      start();
    };
  }, [isLoggedIn]);

  return null;
}

export default UserConnection;