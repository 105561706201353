import { METHOD_TYPES, LOADER_HANDLER_TYPES } from 'constants/index'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

/**
 * Service to fetch game categories
 * @param {object} payload -
 */
export const fetchGameCategoryService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/casino/game-category', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}

/**
 * Service to fetch game sub-categories
 * @param {object} payload -
 */
export const fetchGameSubCategoryService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/casino/game-sub-category', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}

/**
 * Service to get list of providers
 */
export const fetchGameProviderService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, `/casino/game-provider?${new URLSearchParams(payload)}`, {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

/**
 * Service to start game init
 */
export const initGameService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/casino/init-game', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

/**
 * Service to start demo game
 */
export const initDemoGameService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/casino/demo-game', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

/**
 * Service to fetch list of games
 */
export const fetchGamesListService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, `/casino/games?${new URLSearchParams(payload)}`, {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const fetchBannerListService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, `/banner`, payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const addTofavoriteGameService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, `/casino/favorite`, payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}
export const deleteTofavoriteGameService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.delete, `/casino/favorite?${new URLSearchParams(payload)}`,{}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}
export const fetchTofavoriteGamesService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, `/casino/favorite?${new URLSearchParams(payload)}`, {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}
export const fetchFavoriteGameByIdService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, `/casino/gameDetails?${new URLSearchParams(payload)}`,{}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}
