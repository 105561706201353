import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchSocialLinksService } from 'network/services/footer.services'


export const fetchSocialLinksThunk = createAsyncThunk('social/links', async ({ payload }, thunkApi) => {
  try {
    const res = await fetchSocialLinksService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})


