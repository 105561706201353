import { io } from 'socket.io-client'

const createSocketConnection = (connectionUrl, authToken) => {
  const obj = {
    extraHeaders: {
      'access-token': authToken
    },
    auth: {
      'access-token': authToken
    }
  }
  const ioConnection = io(connectionUrl,
    obj
  )

  return ioConnection
}

export default createSocketConnection
