import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchTopWinnersDetailService,fetchCurrentWinnersDetailService } from 'network/services/winners.service'


export const fetchTopWinnersDetailThunk = createAsyncThunk('topWinners/details', async (payload, thunkApi) => {
  try {
    const res = await fetchTopWinnersDetailService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
export const fetchCurrentWinnersDetailThunk = createAsyncThunk('currentWinners/details', async (payload, thunkApi) => {
  try {
    const res = await fetchCurrentWinnersDetailService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})


