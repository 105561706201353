import Cookies from 'universal-cookie'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

import { IS_FIRST_ACCESS, TOKEN, UNIQUE_ID, VISIT_SESSION, COOKIES_CONSENT } from 'constants/index'
import { userFirstVisit } from 'network/services/user.services'

const cookies = new Cookies()

// 24 hour * 60 minutes of every hour
const COOKIE_EXPIRE_MIN = 24 * 60

export const getAuthToken = () => {
  return cookies.get(TOKEN)
}

export const setAuthToken = (authAccessToken) => {
  cookies.set(TOKEN, authAccessToken, {
    path: '/',
    expires: new Date((Date.now() + COOKIE_EXPIRE_MIN * 60 * 1000)),
    sameSite: true
  })
}

export const removeAuthToken = () => {
  cookies.remove(TOKEN, { path: '/' })
}

export const signIn = ({ token }) => {
  setAuthToken(token)
}

export const signOut = () => {
  removeAuthToken()
}

export const isFirstAccess = (set = true) => {
  let hasCookie = Boolean(cookies.get(IS_FIRST_ACCESS));
  const setCookie = (state) => {
    cookies.set(IS_FIRST_ACCESS, state, {
      path: '/',
      expires: new Date((Date.now() + COOKIE_EXPIRE_MIN * 60 * 1000)),
      sameSite: true
    });
  }
  if (!set) return;

  if (!hasCookie) {
    setCookie(true);
  } else {
    setCookie(false);
  }

  return !hasCookie;
}

export const firstVisit = () => {
  const cookieValue = cookies.get(UNIQUE_ID)
  const cookieConfig = { path: '/', sameSite: 'strict', expires: new Date('3024-01-01') }
  if (!Boolean(cookieValue)) {
    cookies.set(UNIQUE_ID, uuidv4(), cookieConfig)
    userFirstVisit(true)
      .then((res) => {
        console.log('res', res)
        const { visitSessionUUID } = res || {}
        cookies.set(VISIT_SESSION, visitSessionUUID, cookieConfig)
      })
      .catch((err) => { })
  } else {
    cookies.set(UNIQUE_ID, cookieValue, cookieConfig)
  }
  // axios.get('http://44.194.7.221:8004/api/v1/user/start', { withCredentials: false, headers: { 'isfirstaccess': 'true' } }).then().catch()
}

export const getVisitSessionCookie = () => {
  return cookies.get(VISIT_SESSION)
}

export const removeVisitSessionCookie = () => {
  cookies.remove(VISIT_SESSION, { path: '/' })
}
export const getCookiesConsent = () => cookies.get(COOKIES_CONSENT);
export const setCookiesConsent = (v) => cookies.set(COOKIES_CONSENT, v);
export const removeCookiesConsent = (v) => cookies.remove(COOKIES_CONSENT);